// sharedChannel.js
import { eventChannel } from 'redux-saga'

let emit;

export const sagaCompletionChannel = eventChannel(emitter => {
    emit = emitter; // Assign the emitter to a variable for external use
    return () => {} // Return a cleanup function (no-op for now)
})

// Export `emit` for other files to use
export const emitSagaEvent = (event) => {
    emit && emit(event) // Emit events only if `emit` is defined
}
