import React, { Component } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
// import find from 'lodash/find';
// import filter from 'lodash/filter';

import DropdownMultiple from './utils/DropdownMultiple';
import { getOptionLabelLocalized } from '../intl-helpers';
import * as c from '../constants';

export default class AttributeAlphanumericWrapper extends Component {
    // state = {
    //     values: []
    // };

    handleChange = selectedItem => {
        // if (find(this.state.values, i => i.value === selectedItem.value)) {
        //     this.removeItem(selectedItem);
        // } else {
        //     this.addSelectedItem(selectedItem);
        // }

        this.props.onCheckboxToggle(this.props.attribute.key, selectedItem.value);
    };

    // handleClear = () => {
    //     this.setState({
    //         values: []
    //     });
    // };

    // addSelectedItem(item) {
    //     this.setState(({ values }) => ({
    //         values: [...values, item]
    //     }));
    // }

    // removeItem = item => {
    //     this.setState(({ values }) => {
    //         return {
    //             values: filter(values, i => i.value !== item.value)
    //         };
    //     });
    // };

    getItems() {
        if (this.props.attribute.data) {
            return this.props.attribute.data;
        }

        return [];
    }

    getSelectedItems() {
        if (typeof this.props.activeFilters === 'undefined') {
            return [];
        }

        // TODO: questi toJS() sarebbero da evitare...
        return this.props.activeFilters
            .map(value => {
                // const option = this.props.attribute.filters.find(
                //     f => f.value === value
                // );

                const option = find(this.props.attribute.data, f => f.value === value);

                return option;
            })
            .toJS();
    }

    render() {
        const { onAttributeResetClick, attribute, onLoad, label, language } = this.props;
        // console.warn(attribute.toJS());

        return (
            <div className="form-group">
                <div className="tooltip" data-tooltip={label}>
                    <label className="form-label label-attributes text-primary">{label}</label>
                </div>
                <DropdownMultiple
                    placeholder={label}
                    isLoading={attribute.isFetching}
                    items={this.getItems()}
                    selectedItem={this.getSelectedItems()}
                    showPlaceholder={false}
                    itemToString={
                        attribute.type === c.ATTRIBUTE_TYPE_RANGE
                            ? item => item?.label || 'Valore non disponibile'
                            : getOptionLabelLocalized(language)
                    }
                    onLoad={() => onLoad(attribute.key)}
                    onChange={this.handleChange}
                    onClear={() => onAttributeResetClick(attribute.key)}
                    onRemove={this.handleChange}
                    showSelectedItemsBadge={true}
                />
            </div>
        );
    }
}

AttributeAlphanumericWrapper.propTypes = {
    activeFilters: PropTypes.object,
    attribute: PropTypes.object,
    onAttributeResetClick: PropTypes.func,
    onLoad: PropTypes.func
};
