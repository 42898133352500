import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import VirtualList from 'react-tiny-virtual-list';
import { Trans, withI18n } from '@lingui/react';
// import fuzzaldrin from 'fuzzaldrin-plus';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';
import matchSorter from 'match-sorter';

// import { matchAndSort /*, profiler*/ } from '../helpers';

/*const fuzzyFilter = (items, input) => {
    return fuzzaldrin.filter(items, input, { key: 'localizedLabel' });
};*/

export function highlightString(query, text, opts = { tag: 'strong', className: 'highlight' }) {
    if (!query || query.length === 0 || query === '') {
        return text;
    }

    const offset = text.toLowerCase().indexOf(query[0].toLowerCase());

    if (offset === -1) {
        return text;
    }

    const fullQueryOffset = text.toLocaleLowerCase().indexOf(query.toLocaleLowerCase());

    if (fullQueryOffset !== -1) {
        const before = text.slice(0, fullQueryOffset);
        const match = text.slice(fullQueryOffset, fullQueryOffset + query.length);
        const highlighted = `<${opts.tag} class="${opts.className}">${match}</${opts.tag}>`;
        const after = text.slice(fullQueryOffset + query.length);

        return `${before}${highlighted}${after}`;
        // return `${before}${highlighted}${after}`.replace(/\s/g, '&nbsp;');
    }

    let last = 0;

    for (let i = 1; i < query.length; i++) {
        if (text[offset + i] !== query[i]) {
            break;
        }

        last = i;
    }

    const before = text.slice(0, offset);
    const match =
        `<${opts.tag} class="${opts.className}">` +
        text.slice(offset, offset + last + 1) +
        `</${opts.tag}>`;
    const after = highlightString(query.slice(last + 1), text.slice(offset + last + 1), opts);

    return after === null ? null : `${before}${match}${after}`;
    // return after === null ? null : `${before}${match}${after}`.replace(/\s/g, '&nbsp;');
}

class DropdownList extends Component {
    renderIcon(selectedItem, isOpen, clearSelection, getToggleButtonProps) {
        const { disabled, isLoading, clearable, showClearAndSelectBtn } = this.props;

        if (disabled) {
            return null;
        }

        if (isLoading) {
            return <i className="form-icon loading" />;
        }

        const res = [];

        if (selectedItem && clearable) {
            res.push(
                <i
                    className="form-icon icon icon-cross c-hand"
                    style={{ right: '1rem' }}
                    disabled={disabled}
                    onClick={() => (disabled ? false : clearSelection())}
                    key="clear"
                />
            );
        }

        if (showClearAndSelectBtn) {
            res.push(
                <i
                    className={`form-icon icon icon-arrow-${isOpen ? 'up' : 'down'} c-hand`}
                    {...getToggleButtonProps({ disabled })}
                    key="arrow"
                />
            );
        }

        return res;
    }

    getDisplayItems = (items, value) => {
        // In questo modo non mi aggiorna le opzioni mentre scrivo, il che non è del tutto desiderabile...
        // if (this.props.autoSortItems === false) {
        //     return items;
        // }

        // console.warn(this.props);
        // console.warn(this.props.selectedItem);
        // console.warn(items);
        // console.warn(value);
        if (this.props.selectedItem && this.props.itemToString(this.props.selectedItem) === value) {
            return items;
        }

        if (this.props.selectedItem && this.props.itemToString(this.props.selectedItem) === value) {
            return items;
        }

        // if (this.props.clearable === false && this.props.selectedItem !== null) {
        //     return items;
        // }

        if (value === '' || value === null || value === undefined) {
            return items;
        }

        // const result = matchAndSort(items, value, [this.props.itemLabelKey]);

        // console.log(profiler.log());

        // TODO: sarebbe il caso di memorizzare il risultato...
        items = items.map((item) => {
            const localizedLabel = this.props.itemToString(item);

            return {
                ...item,
                localizedLabel,
                sortingLabel: this.props.itemToSortString
                    ? this.props.itemToSortString(item)
                    : localizedLabel,
            };
        });

        const result = matchSorter(items, value, {
            keys: ['localizedLabel'],
            threshold: matchSorter.rankings.CONTAINS,
        });

        // console.log(result);

        // const result = fuzzyFilter(items, value);

        // return result;

        return sortBy(result, (r) => r.sortingLabel.toLowerCase());
    };

    handleChange = (item) => {
        const { onChange, selectedItem } = this.props;

        if (item === null) {
            return onChange(item);
        }

        if (selectedItem && selectedItem.value === item.value) {
            return true;
        }

        onChange(item);
    };

    renderNormalList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps
    ) {
        //console.log("renderNormalList", displayItems)
        // console.warn(inputValue);

        return (
            <div className="menu" style={{ transform: 'none', padding: 0 }}>
                {displayItems.map((item, index) => {
                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem === item,
                    });

                    // console.warn(itemToString(item));

                    // console.log(itemToString(item));
                    // console.log(highlightString(inputValue, itemToString(item)));

                    const itemLabel =
                        selectedItem && itemToString(selectedItem) === inputValue
                            ? itemToString(item) === inputValue
                                ? highlightString(inputValue, itemToString(item))
                                : itemToString(item)
                            : highlightString(inputValue, itemToString(item));

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item,
                            })}
                        >
                            {inputValue === '' || typeof inputValue === 'undefined' ? (
                                <span>{itemToString(item)}</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: itemLabel,
                                        // __html: fuzzaldrin.wrap(itemToString(item), inputValue),
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderVirtualList(
        displayItems,
        highlightedIndex,
        selectedItem,
        inputValue,
        itemValueKey,
        itemToString,
        getItemProps
    ) {

        return (
            <VirtualList
                width="100%"
                scrollToIndex={highlightedIndex || 0}
                scrollToAlignment="auto"
                height={displayItems.length < 10 ? displayItems.length * 34 : 340}
                itemCount={displayItems.length}
                itemSize={34}
                renderItem={({ index, style }) => {
                    const item = displayItems[index];

                    const itemClassName = cx('dropdownList-menu-item', 'c-hand', {
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem === item,
                        'disabled': item.disabled,
                    });

                    const itemLabel =
                        selectedItem && itemToString(selectedItem) === inputValue
                            ? itemToString(item) === inputValue
                                ? highlightString(inputValue, itemToString(item))
                                : itemToString(item)
                            : highlightString(inputValue, itemToString(item));

                    return (
                        <div
                            key={item[itemValueKey]}
                            className={itemClassName}
                            {...getItemProps({
                                index,
                                item,
                                style,
                            })}
                        >
                            {inputValue === '' ? (
                                <span>{itemToString(item)}</span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: itemLabel,
                                        // __html: fuzzaldrin.wrap(itemToString(item), inputValue),
                                    }}
                                />
                            )}
                            {/* <Highlighter
                                highlightClassName="search-highlight"
                                highlightTag="span"
                                searchWords={[
                                    inputValue
                                ]}
                                autoEscape={true}
                                textToHighlight={itemToString(
                                    item
                                )}
                            /> */}
                        </div>
                    );
                }}
            />
        );
    }

    // componentDidUpdate(prevProps) {
    //     console.warn('Diff: ', prevProps.items !== this.props.items);
    // }

    render() {
        const {
            clearable,
            disabled,
            items,
            itemToString,
            isLoading,
            placeholder,
            itemLabelKey,
            itemValueKey,
            onChange,
            i18n,
            wrapperStyle,
            onWrapperClick,
            onFocus,
            onBlur,
            className,
            wrapperClassName,
            inputClassName,
            useVirtualList,
            filterWhenValue,
            ...rest
        } = this.props;

        return (
            <Downshift
                itemToString={itemToString}
                itemCount={items.length}
                onChange={this.handleChange}
                {...rest}
            >
                {({
                    getInputProps,
                    getToggleButtonProps,
                    getItemProps,
                    isOpen,
                    // toggleMenu,
                    clearSelection,
                    selectedItem,
                    inputValue,
                    highlightedIndex,
                }) => {
                    // console.log(inputValue);
                    // console.log(selectedItem && itemToString(selectedItem));

                    const displayItems = this.getDisplayItems(items, inputValue);

                    // const displayItems = filterWhenValue || (selectedItem && itemToString(selectedItem) !== inputValue)
                    //     ? this.getDisplayItems(items, inputValue)
                    //     : items.map((item) => {
                    //           const localizedLabel = this.props.itemToString(item);

                    //           return {
                    //               ...item,
                    //               localizedLabel,
                    //           };
                    //       });

                    // console.log(this.props);
                    // console.log(displayItems);

                    // Non mostro niente nell'input se il menu è chiuso e non e stato selezionato nessun oggetto
                    const defaultInputProps = getInputProps({
                        disabled,
                        className: inputClassName,
                    });

                    if (
                        (isOpen === false && selectedItem === null) ||
                        defaultInputProps.value === null
                    ) {
                        defaultInputProps.value = '';
                    }

                    const renderList = useVirtualList
                        ? this.renderVirtualList
                        : this.renderNormalList;

                    const dropdownClassName = cx(
                        'dropdownList',
                        'has-icon-right',
                        className,
                        wrapperClassName,
                        {
                            isDisabled: this.props.disabled,
                        }
                    );

                    // console.log(defaultInputProps);

                    return (
                        <div
                            className={dropdownClassName}
                            style={wrapperStyle}
                            onClick={onWrapperClick}
                        >
                            <input
                                type="text"
                                placeholder={
                                    placeholder ? i18n._(placeholder) : i18n._('filter:by')
                                }
                                {...defaultInputProps}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            />
                            {this.renderIcon(
                                selectedItem,
                                isOpen,
                                clearSelection,
                                getToggleButtonProps
                            )}
                            {!isOpen ? null : (
                                <div className="dropdownList-menu">
                                    {displayItems.length === 0 ? (
                                        <div className="dropdownList-menu-item">
                                            {isLoading ? (
                                                <Trans id="Please wait" />
                                            ) : (
                                                <Trans id="no:result" />
                                            )}
                                        </div>
                                    ) : (
                                        renderList(
                                            displayItems,
                                            highlightedIndex,
                                            selectedItem,
                                            inputValue,
                                            itemValueKey,
                                            itemToString,
                                            getItemProps
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

DropdownList.propTypes = {
    autoSortItems: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    itemToString: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onWrapperClick: PropTypes.func,
    itemLabelKey: PropTypes.string,
    itemValueKey: PropTypes.string,
    placeholder: PropTypes.string,
    showClearAndSelectBtn: PropTypes.bool,
    wrapperStyle: PropTypes.object,
    inputClassName: PropTypes.string,
    useVirtualList: PropTypes.bool,
    filterWhenValue: PropTypes.bool,
    wrapperClassName: PropTypes.string,
};

DropdownList.defaultProps = {
    autoSortItems: true,
    className: '',
    clearable: true,
    disabled: false,
    isLoading: false,
    items: [],
    itemToString: (i) => (i ? i.label : ''),
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onWrapperClick: () => {},
    itemLabelKey: 'label',
    itemValueKey: 'value',
    showClearAndSelectBtn: true,
    wrapperStyle: {},
    inputClassName: '',
    useVirtualList: true,
    filterWhenValue: true,
    wrapperClassName: '',
};

export default withI18n()(DropdownList);
