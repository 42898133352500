import React, { Component, Fragment } from 'react';
import Slider from 'rc-slider';
import isNaN from 'lodash/isNaN';
// import debounce from 'lodash/debounce';

import ClickOutside from './utils/ClickOutside';

import { connect } from 'react-redux';
import { getIsLoadingFromLocalStorage } from '../reducers/pageStateReducer';
import { getFilterFromLocalStorage, getFromLocalStorage } from '../utils/LocalStorageFilterUtils'; // Import dal reducer

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

class AttributeNumericWrapper extends Component {
    constructor(props) {
        super(props);

        // console.log(props);

        this.state = {
            // isSet: false,
            isOpen: false,
            start: props.start,
            end: props.end,
            startRange: props.start,
            endRange: props.end,
        };

        // console.warn(this.state);

        // this.validateRange = debounce(this.validateRange, 1000);
    }

    componentDidUpdate(prevProps /*, prevState*/) {
        const { min, max, isActive, loadingFromLocalStorage, attribute } = this.props;

        // Verifica se attribute è definito e ha la proprietà key
        const attributeKey = attribute && attribute.key ? attribute.key : null;

        if (prevProps.min !== min || prevProps.max !== max) {
            this.setState({
                start: min,
                end: max,
                startRange: min,
                endRange: max,
            });
        }

        if (prevProps.isActive === true && isActive === false) {
            this.setState({
                start: min,
                end: max,
                startRange: min,
                endRange: max,
            });
        }

        if (loadingFromLocalStorage && attributeKey) {
            //console.warn('Caricamento da localStorage per l\'attributo ', attribute);

            const storedFilters = getFilterFromLocalStorage('etim_features') || {};
            const storedFeature = storedFilters[attributeKey];
            // console.warn('siamo qui !!', storedFilters)
            if (storedFeature && storedFeature.type === 'numeric') {
                const [start, end] = storedFeature.value || [];

                //  Set the state only if stored values aren't equal the state ones
                if (
                    start !== undefined &&
                    end !== undefined &&
                    (start !== this.state.start || end !== this.state.end)
                ) {
                    this.setState({
                        start,
                        end,
                        startRange: start,
                        endRange: end,
                    });
                }
            }
        }
    }


    validateRange = () => {
        // TODO: da migliorare
        const { min, max } = this.props;

        let newStart = parseFloat(this.state.start);
        let newEnd = parseFloat(this.state.end);

        if (isNaN(newEnd)) {
            newEnd = max;
        }

        if (isNaN(newStart)) {
            newStart = min;
        }

        if (this.state.start < min) {
            newStart = min;
            // this.setState({
            //     start: min,
            //     startRange: min
            // });
        }

        if (this.state.start > max) {
            newStart = max;
            // this.setState({
            //     start: max,
            //     startRange: max
            // });
        }

        if (this.state.end < min) {
            newEnd = min;
            // this.setState({
            //     end: min,
            //     endRange: min
            // });
        }

        if (this.state.end > max) {
            newEnd = max;
            // this.setState({
            //     end: max,
            //     endRange: max
            // });
        }

        if (this.state.end < this.state.start) {
            newEnd = this.state.start;
        }

        this.setState({
            start: newStart,
            startRange: newStart,
            end: newEnd,
            endRange: newEnd,
        });
    };

    handleToggleMenu = () => {
        this.props.onLoad(this.props.attribute.key)
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    };

    setEnd = (e) => {
        this.setState({
            end: parseFloat(e.target.value),
        });
    };

    // handleRangeChange = (value, type) => {
    //     let n = parseFloat(value);

    //     // TODO: da migliorare: se il numero presente è decimale, ad esempio 0.55, non posso modificarlo semplicemente
    //     // usando il backspace perchè a 0 si ferma, devo necessariamente selezionare tutto il testo e modificarlo
    //     if (isNaN(n)) {
    //         return false;
    //     }

    //     // TODO: controllo che genera problemi con i numeri decimali per il valore minimo, supponendo un valore minimo di 0.55 ad esempio
    //     // non riesco a scrivere 0.65 perché cancellando l'ultima cifra diventerebbe 0.5 per un istante e quindi minore di 0.55!
    //     // Dovrei inserire un altro attributo nello state per mantenere diversificato lo stato degli input da quelli di start e end
    //     // if (n < min) {
    //     //     n = min;
    //     // } else if (n > max) {
    //     //     n = max;
    //     // }

    //     this.setState({
    //         [type]: n,
    //         [`${type}Range`]: n
    //     });
    // };

    handleConfirm = () => {
        this.props.onChange(this.props.attribute.key, [this.state.start, this.state.end]);

        this.setState({
            isOpen: false,
        });
    };

    resetValues = () => {
        this.props.onClear(this.props.attribute.key);
        // this.setState({
        //     isSet: false
        // });
    };

    render() {
        const { attribute, min, max, isActive, label, rangeSuffix, freeStep } = this.props;
        const { isOpen, start, end, startRange, endRange } = this.state;

        const step = isFloat(min) || isFloat(max) ? 0.1 : 1; // any
        //console.log('###### Attribute numeric render props => ', this.props.loadingFromLocalStorage)
        //console.warn('Attribute numeric render state => ', isOpen, start, end, startRange, endRange)
        // console.warn(min, max, start, end);

        const hasNoValues = min === null && max === null;

        return (
            <ClickOutside onClickOutside={() => this.setState({ isOpen: false })}>
                <div className="form-group">
                    <div className="tooltip" data-tooltip={label}>
                        <label className="form-label label-attributes text-primary">{label}</label>
                    </div>
                    <div className="dropdownList has-icon-right">
                        <div className="inputWrapper">
                            <input style={{ width: 0 }} />
                            {isActive && (
                                <span className="label label-rounded label-primary">
                                    Tra {isNaN(start) ? '-' : start} e {isNaN(end) ? '-' : end}{' '}
                                    {rangeSuffix || ' '}
                                    <i
                                        className="form-icon icon icon-cross c-hand"
                                        style={{ right: '1rem' }}
                                        onClick={this.resetValues}
                                    />
                                </span>
                            )}
                            <i
                                className={`form-icon icon icon-arrow-${
                                    isOpen ? 'up' : 'down'
                                } c-hand`}
                                onClick={this.handleToggleMenu}
                            />
                        </div>
                        {isOpen && (
                            <div className="dropdownList-menu">
                                <div
                                    className="dropdownList-menu-item"
                                    style={{
                                        margin: hasNoValues ? 0 : '0 0 20px 0',
                                    }}
                                >
                                    {attribute.isFetching ? (
                                        <div className="text-center">
                                            <i className="form-icon loading" />
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-12">
                                            {hasNoValues ? (
                                                <div className="text-center pt-2">
                                                    Nessun risultato
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    <div className="col-span-12 mb-2 pt-2">
                                                        {/* <div style={{ marginBottom: '10px' }}>Valori <b>{min}</b> a <b>{max}</b></div> */}
                                                        <Range
                                                            min={min}
                                                            max={max}
                                                            value={[startRange, endRange]}
                                                            step={step}
                                                            allowCross={false}
                                                            onChange={([s, e]) =>
                                                                this.setState({
                                                                    startRange: s,
                                                                    endRange: e,
                                                                    start: s,
                                                                    end: e,
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-span-5">
                                                        <input
                                                            type="number"
                                                            className="form-input form-number"
                                                            min={min}
                                                            max={isNaN(end) ? max : end}
                                                            step={freeStep ? 0.0001 : step}
                                                            value={start}
                                                            onChange={(e) =>
                                                                this.setState({
                                                                    start: parseFloat(
                                                                        e.target.value
                                                                    ),
                                                                })
                                                            }
                                                            onBlur={this.validateRange}
                                                        />
                                                    </div>
                                                    <div className="col-span-5">
                                                        <input
                                                            type="number"
                                                            className="form-input form-number"
                                                            //min={start}
                                                            min={min}
                                                            max={max}
                                                            step={freeStep ? 0.0001 : step}
                                                            value={end}
                                                            onChange={this.setEnd}
                                                            onBlur={this.validateRange}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <button
                                                            className="btn btn-action btn-primary"
                                                            onClick={this.handleConfirm}
                                                        >
                                                            <i className="icon icon-check" />
                                                        </button>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

// Map Redux state to component props
const mapStateToProps = (state) => ({
    loadingFromLocalStorage: getIsLoadingFromLocalStorage(state),
});

export default connect(mapStateToProps)(AttributeNumericWrapper);
