// src/utils/LocalStorageFilterUtils.js
const filterMap = {
    status: 'stato',
    line: 'linea',
    model: 'modello',
    series: 'serie',
    fuoriCartaceo: 'fuori_listino_cartaceo',
    brand: 'sigle_marca',
    prezzo_listino_from: 'prezzo_listino_from',
    prezzo_listino_to: 'prezzo_listino_to',
    classification: 'product_tree'
    // Add more maps if needed
}

const getMappedFilterKey = (key) => {
    return filterMap[key] || key;
}

export const isLocalStorageAvailable = () => {
    try {
        if (typeof localStorage === 'undefined') {
            return false;
        }

        const storedFilters = localStorage.getItem('userFilters');
        return storedFilters !== null;
    } catch (error) {
        console.error('Error accessing localStorage:', error);
        return false;
    }
}
export const initializeLocalStorage = (apiPayload) => {
    try {
        if (typeof localStorage === 'undefined') {
            console.error('localStorage is not available.');
            return;
        }

        // Check if 'userFilters' already exists in localStorage
        const storedFilters = localStorage.getItem('userFilters');

        if (storedFilters === null) {
            // If userFilters doesn't exist, initialize it with apiPayload
            localStorage.setItem('userFilters', JSON.stringify(apiPayload));
            console.log('userFilters initialized with apiPayload.');
        } else {
            console.log('userFilters already exists.');
        }

    } catch (error) {
        console.error('Error initializing localStorage:', error);
    }
}
export const clearUserFilters = () => {
    try {
        if (typeof localStorage === 'undefined') {
            console.error('localStorage is not available.');
            return;
        }

        // Remove 'userFilters' from localStorage
        localStorage.removeItem('userFilters');
        console.log('userFilters cleared from localStorage.');
    } catch (error) {
        console.error('Error clearing userFilters from localStorage:', error);
    }
};

export const updateLocalStorage = (key, value, action = 'add') => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    if (action === 'add') {
        if (Array.isArray(storedFilters[mappedKey])) {
            // If array already have this value, we don't add avoiding duplicates
            if (!storedFilters[mappedKey].includes(value)) {
                storedFilters[mappedKey].push(value);
            }
        } else {
            // If isn't an array, we create an array with that value
            storedFilters[mappedKey] = [value];
        }
    } else if (action === 'remove') {
        if (Array.isArray(storedFilters[mappedKey])) {
            // Remove the value from array
            storedFilters[mappedKey] = storedFilters[mappedKey].filter(item => item !== value);

            if (storedFilters[mappedKey].length === 0) {
                delete storedFilters[mappedKey];
            }
        } else {
            delete storedFilters[mappedKey];
        }
    }

    // Add or remove item from localStorage
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
}

export const updateLocalStorageStringValue = (key, value, action = 'add') => {
    const mappedKey = getMappedFilterKey(key);
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    if (action === 'add') {
        // Set the string value
        storedFilters[mappedKey] = value;
    } else if (action === 'remove') {
        // Remove the key if it exists
        if (storedFilters[mappedKey]) {
            delete storedFilters[mappedKey];
        }
    }

    // Update or remove from localStorage based on storedFilters content
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
}

export const getFilterFromLocalStorage = (key) => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {}

    return storedFilters[mappedKey] || null;
}

export const removeFilterFromLocalStorage = (key) => {
    const mappedKey = getMappedFilterKey(key)
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {};

    // Check if the mappedKey exists and either remove it if false or delete the key
    if (storedFilters[mappedKey] !== undefined) {
        delete storedFilters[mappedKey];
    }

    // Update localStorage based on the contents of storedFilters
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters');
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters));
    }
};

export const removeFiltersFromLocalStorage = (keys) => {
    const storedFilters = JSON.parse(localStorage.getItem('userFilters')) || {}
    //console.warn(keys)
    // Loop through keys and remove each mapped key if it exists
    keys.forEach((key) => {
        const mappedKey = getMappedFilterKey(key)
        if (storedFilters[mappedKey] !== undefined) {
            delete storedFilters[mappedKey]
        }
    })
    // Update localStorage based on the contents of storedFilters
    if (Object.keys(storedFilters).length === 0) {
        localStorage.removeItem('userFilters')
    } else {
        localStorage.setItem('userFilters', JSON.stringify(storedFilters))
    }
}

export const removeBrandRelatedFilterFromLocalStorage = (key = 'all') => {
    if(key !== 'all' && key !== undefined){
        removeFilterFromLocalStorage(key)
    }
    if(key === 'all'){
        removeFilterFromLocalStorage('stato')
        removeFilterFromLocalStorage('line')
        removeFilterFromLocalStorage('model')
        removeFilterFromLocalStorage('series')
        removeFilterFromLocalStorage('listini')
    }

}

export const deleteEtimFeatureInLocalStorage = (key) => {
    const storedFeatures = getFilterFromLocalStorage('etim_features') || {}
    // If etim_features key doesn't exist, return
    if (!storedFeatures[key]) return

    // If etim_features key exist, remove it
    delete storedFeatures[key]

    if (Object.keys(storedFeatures).length === 0) {
        removeFilterFromLocalStorage('etim_features')
    } else {
        updateLocalStorageStringValue('etim_features', JSON.stringify(storedFeatures))
        //localStorage.setItem('etim_features', JSON.stringify(storedFeatures)) /// responsabile errore !!! fix
    }
}

export const getStoredFilters = (key = 'userFilters') => {
    const storedFilters = localStorage.getItem(key)

    if (!storedFilters) {
        return null
    }

    return JSON.parse(storedFilters)
}
export const clearLocalStorage = () => {
    localStorage.removeItem('userFilters');
};

export const createLocalStorage = ((name, values = JSON.stringify({})) => {
    if(typeof values === 'undefined') {}

    if(typeof values === 'object') {
        values = JSON.stringify(values)
    }
    localStorage.setItem(name, values)
})

export const getFromLocalStorage = (key) => {
    const obj = localStorage.getItem(key)
    if (!obj) return null
    if(typeof obj === 'undefined') return null
    return JSON.parse(obj)
}