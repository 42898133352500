import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import get from 'lodash/get';

import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import { actions as productClassesActions } from '../actions/productClassesActions';
import SectorsTree from '../containers/SectorsTree';
import { actions } from '../actions/filtersActions';
import { actions as sectorsActions } from '../actions/sectorsActions';
import * as constants from '../constants';
import { getOptionLabelLocalized, getLocaleFromLanguageCode } from '../intl-helpers';
import AttributeNumericWrapper from './AttributeNumericWrapper';
import { getFilterFromLocalStorage } from '../utils/LocalStorageFilterUtils';

function getListiniLabel(language) {
    return function (option) {
        const baseLabel = getOptionLabelLocalized(language)(option);

        if (option.extra && option.extra.codice_revisione !== '00') {
            const locale = getLocaleFromLanguageCode(language);
            const descrizione = get(option.extra, `descrizione_revisione.${locale}`, option.extra.codice_revisione);
            return `${baseLabel} - ${descrizione}`;
        }

        return baseLabel;
    };
}

export class Sidebar extends Component {
    render() {
        const {
            i18n,
            classificationFilter,
            brandFilter,
            isBrandFixed,
            statusFilter,
            priceRangeFilter,
            fuoriCartaceoFilter,
            esportabilePerCatalogoFilter,
            height,
            language,
            showPriceRangeFilter,
            lineFilter,
            modelFilter,
            seriesFilter,
            listiniFilter,
            productClassFilter,
            validFilterOptions,
            enableEsportabilePerCatalogoFilter,
        } = this.props

        // Mappa dei filtri da rendere dinamicamente
        const filters = [
            {
                key: 'brandFilter',
                condition: !isBrandFixed,
                component: (
                    <DropdownListFilterWrapper
                        filter={brandFilter}
                        placeholder={i18n._('filter:brand')}
                        disabled={isBrandFixed}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) => this.props.dispatch(actions.changeBrandFilterValue(item))}
                        multiple={true}
                    />
                ),
            },
            {
                key: 'statusFilter',
                condition: statusFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={statusFilter}
                        placeholder={i18n._('filter:status')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onStatusChange
                                ? this.props.onStatusChange(item)
                                : this.props.dispatch(actions.setFilterValue(constants.STATUS_FILTER, item))
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'fuoriCartaceoFilter',
                condition: fuoriCartaceoFilter,
                component: (
                    <DropdownListFilterWrapper
                        filter={fuoriCartaceoFilter}
                        placeholder={i18n._('filter:fuoriCartaceo')}
                        validFilterOptions={null}
                        itemToString={(option) => i18n._(get(option, 'label'))}
                        //onChange={(item) => this.props.dispatch(actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, item))}
                        onChange={(item) =>
                            this.props.onFuoriCartaceoChange
                            ? this.props.onFuoriCartaceoChange(item)
                            : this.props.dispatch(actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, item))
                        }
                    />
                ),
            },
            {
                key: 'seriesFilter',
                condition: seriesFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={seriesFilter}
                        placeholder={i18n._('filter:series')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onSeriesChange
                                ? this.props.onSeriesChange(item)
                                : this.props.dispatch(actions.setFilterValue(constants.SERIES_FILTER, item))
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'lineFilter',
                condition: lineFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={lineFilter}
                        placeholder={i18n._('filter:line')}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onLineChange
                                ? this.props.onLineChange(item)
                                : this.props.dispatch(actions.setFilterValue(constants.LINE_FILTER, item))
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'modelFilter',
                condition: modelFilter.options.count() > 0,
                component: (
                    <DropdownListFilterWrapper
                        filter={modelFilter}
                        placeholder={i18n._('filter:model')}
                        useVirtualList={false}
                        itemToString={getOptionLabelLocalized(language)}
                        onChange={(item) =>
                            this.props.onModelChange
                                ? this.props.onModelChange(item)
                                : this.props.dispatch(actions.setFilterValue(constants.MODEL_FILTER, item))
                        }
                        multiple={true}
                    />
                ),
            },
            {
                key: 'listiniFilter',
                condition: listiniFilter.options.count() > 0 && (isBrandFixed || brandFilter.value.count() === 1),
                component: (
                    <DropdownListFilterWrapper
                        filter={listiniFilter}
                        placeholder={i18n._('filter:listini')}
                        useVirtualList={false}
                        itemToString={getListiniLabel(language)}
                        itemToSortString={(o) => `${o.extra.codice_listino}_${o.extra.codice_revisione}`}
                        onChange={(item) =>
                            this.props.onListiniChange
                                ? this.props.onListiniChange(item)
                                : this.props.dispatch(actions.setFilterValue(constants.LISTINI_FILTER, item))
                        }
                        multiple={true}
                    />
                ),
            },
        ];

        return (
            <div>
                {/* Renderizzazione dinamica dei filtri */}
                {filters.map(({ key, condition, component }) => condition && <Fragment key={key}>{component}</Fragment>)}

                {/* Filtro per intervallo di prezzo */}
                {showPriceRangeFilter && (
                    <Fragment>
                        <div className="divider" />
                        <AttributeNumericWrapper
                            label={i18n._('priceRange')}
                            rangeSuffix="€"
                            freeStep={true}
                            min={priceRangeFilter.min}
                            max={priceRangeFilter.max}
                            start={priceRangeFilter.value[0]}
                            end={priceRangeFilter.value[1]}
                            isActive={priceRangeFilter.value[0] !== 0 || priceRangeFilter.value[1] !== 0}
                            attribute={{}}
                            onLoad={() => {}}
                            onClear={() => this.props.dispatch(actions.setFilterValue(constants.PRICE_RANGE_FILTER, [0, 0]))}
                            onChange={(_, range) => this.props.dispatch(actions.setFilterValue(constants.PRICE_RANGE_FILTER, range))}
                        />
                    </Fragment>
                )}

                <div className="divider" />
                <h6 className="text-uppercase text-primary">
                    <Trans id="choose:by:taxonomy" />
                </h6>
                <DropdownListFilterWrapper
                    filter={classificationFilter}
                    placeholder={i18n._('filter:classification')}
                    clearable={false}
                    itemToString={getOptionLabelLocalized(language)}
                    // onChange={(item) => this.props.dispatch(sectorsActions.setClassificationValue(item))}
                    onChange={(item) =>
                        this.props.onClassificationChange
                            ? this.props.onClassificationChange(item)
                            : this.props.dispatch(sectorsActions.setClassificationValue(item))

                    }
                    useVirtualList={false}
                />
                {classificationFilter.value?.code === 'etim' && (
                    <>
                        <h6 className="text-uppercase text-primary">Classe prodotto</h6>
                        <DropdownListFilterWrapper
                            filter={productClassFilter}
                            validFilterOptions={validFilterOptions}
                            placeholder={i18n._('filter:product:class')}
                            itemToString={(option) =>
                                option ? `${option.code ? option.code : '-'} ${getOptionLabelLocalized(language)(option)}` : ''
                            }
                            itemToSortString={getOptionLabelLocalized(language)}
                            onChange={(item) =>
                                this.props.onEtimClassChange ?
                                    this.props.onEtimClassChange(item)
                                    : this.props.dispatch(productClassesActions.setProductClassValue(item))}
                            useVirtualList={false}
                        />
                    </>
                )}
                <SectorsTree
                    sidebarHeight={height}
                    classificationType={classificationFilter.value?.code}
                    onClassificationPathChange={this.props.onClassificationPathChange}
                    onEtimClassChange={this.props.onEtimClassChange}
                />
            </div>
        );
    }
}

Sidebar.propTypes = {
    classificationFilter: PropTypes.object.isRequired,
    brandFilter: PropTypes.object.isRequired,
    i18n: PropTypes.object,
    isBrandFixed: PropTypes.bool,
    statusFilter: PropTypes.object.isRequired,
    showPriceRangeFilter: PropTypes.bool.isRequired,
    //selectedItem: PropTypes.string,
    height: PropTypes.number,
    language: PropTypes.string,
    validFilterOptions: PropTypes.object,
};

export default withI18n()(Sidebar);
