import { call, fork, put, select, takeEvery, all } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { fromJS, hash } from 'immutable';
import { toast } from 'react-toastify';

import { actionTypes, actions } from '../actions/itemsActions';
import { actions as collectionsActions } from '../actions/collectionsActions';
import { disableFilters, enableFilters } from '../actions/filtersActions';
import {
    restoreBootstrappedState,
    bootstrapAppSuccess,
    CHANGE_CURRENT_TABLE_VIEW,
} from '../actions/appActions';
// import { fetchSectors } from '../actions/sectorsActions';
import { selectors, nonFiltersKeys } from '../reducers/filtersReducer';
import { selectors as itemsSelectors } from '../reducers/itemsReducer';
import { selectors as collectionsSelectors } from '../reducers/collectionsReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { refreshFiltersValues } from './filtersSaga';
import { fetchItemsCustom, getArticlesCollections, removeItem } from '../api';
import * as constants from '../constants';
import { fetchTree } from './sectorsSaga';
import {
    clearUserFilters,
    getFilterFromLocalStorage,
    initializeLocalStorage,
    isLocalStorageAvailable, removeFiltersFromLocalStorage
} from '../utils/LocalStorageFilterUtils';
import { emitSagaEvent } from './sharedChannel';

const controlledFiltersKeys = [
    constants.STATUS_FILTER,
    constants.OPTIONALS_FILTER,
    constants.REPLACEMENTS_FILTER,
    constants.FUORI_CARTACEO_FILTER,
    constants.ESPORTABILE_PER_CATALOGO_FILTER,
];

export const DEFAULT_VIEWS_TABLE_COLUMNS = [
    'prezzo_listino',
    'data_inizio_validita_prezzo',
    'codice_articolo',
    'descrizione_articolo',
    'descrizione_marca',
    'single_serie',
    'linea',
    'modello',
    'unita_ordine',
    'unita_contenuta',
    'quantita_contenuta',
    'descrizione_marca',
    'prezzi',
    'serie',
    'linee',
    'etim',
    'etim_class',
    'unita_per_prezzo',
];
// TODO: Non posso evitare di considerare "include_accessori" e "include_ricambi"
// perchè di base non ci sono e una volta selezionato qualcosa diventano true
// questo può portare a falsi negativi quando questi filtri cambiano e la pagina
// non viene però riportata a 1...
// const nonRelevantPayloadKeys = ['per_page', 'page', 'classification'];

export function getHashFromPayload(payload) {
    return hash(fromJS(omit(payload, nonFiltersKeys)));
}

// let iterations = 0;

function* fetchItemsSaga(action) {
    console.log("###### STARTING FETCH ITEMS SAGA ######")
    const { payload: options } = action;

    let apiPayload = yield select(selectors.getApiPayload);

    console.warn('apiPayload from fetchItemsSaga', apiPayload, action)

    const currentHash = getHashFromPayload(apiPayload);
    const previousHash = yield select(itemsSelectors.getLastSearchHash);

    const engagedCollection = yield select(collectionsSelectors.getEngagedCollection);

    const shownCollection = yield select(collectionsSelectors.getShownCollection);

    const hasFilters = yield select(selectors.isAnyFilterActive);

    // console.log('hasFilters is any filter active => ', hasFilters)

    // Se non ci sono filtri ed esiste una collezione ingaggiata o mostrata allora fetcho solo gli articoli della collezione
    if (hasFilters === false && (engagedCollection || shownCollection)) {
        console.warn('Prima if hasFilters === false && engagedCollection || shownCollection')
        yield put(actions.fetchCollectionItems());

        // Se i filtri sono cambiati...
        if (currentHash !== previousHash && previousHash !== null) {
            // console.log('currentHash !== previousHash && previousHash !== null')
            // Ristoro lo stato iniziale dell'applicazione, a parte i settori se la classificazione prescelta non é quella di default
            const currentClassification = yield select(sectorsSelectors.getFilterValue);
            console.warn('BOOTSTRAPPED APP CALLED FROM itemsSaga ln:104')
            yield put(
                restoreBootstrappedState({
                    restoreSectors: currentClassification === constants.DEFAULT_CLASSIFICATION,
                })
            );
        }

        return;
        // altrimenti resetto gli oggetti in tabella
    } else if (hasFilters === false) {
        console.warn('Seconda if hasFilters === false')

        // LOCAL STORAGE - here we check if is stored a Classification, if is stored we don't need to reset items
        if (getFilterFromLocalStorage('product_tree') === null){
            yield put(actions.resetItems());
            yield put(actions.resetSelectedItems());
        }

        // e come sopra riporto l'applicazione al suo stato iniziale
        if (currentHash !== previousHash) {
            const currentClassification = yield select(sectorsSelectors.getFilterValue);

            const shouldRestoreSectors = currentClassification === constants.DEFAULT_CLASSIFICATION;
            console.warn('BOOTSTRAPPED APP CALLED FROM itemsSaga ln:128')
            console.warn('shouldRestoreSectors', shouldRestoreSectors)
            // Here the problem is that restoreBootstrappedState is needed to load changed classification data
            // Need to find a good strategy to handle this
            yield put(
                restoreBootstrappedState({
                    restoreSectors: shouldRestoreSectors,
                })
            );

            // Se però non ristoro i sectors rifetcho comunque l'albero
            if (shouldRestoreSectors === false) {
                yield fork(fetchTree);
            }
        }
        // LOCAL STORAGE: check if exist userFilters in localStorage, if isn't setted up, setup with current apiPayload value
        if(!isLocalStorageAvailable()){
            //console.log('##### non local storage available', apiPayload)
            initializeLocalStorage(apiPayload)
        }
         return
    }

    // Se l'hash della ricerca attuale è diverso (è cambiato qualche filtro) aggiorno il valore dell'hash e riporto la pagina a 1
    if (currentHash !== previousHash && options.bootstrap !== true) {
        //console.warn('FILTERS HAVE CHANGED!!!', currentHash, previousHash);
        yield put(actions.setLastSearchHash(currentHash));

        yield put(actions.changePage(0));
        //yield put(actions.fetchItems())
        return
    }

    // Se esistono filtro od ordinamento...
    const hasFiltersOrOrder = yield select(selectors.isAnyFilterActiveOrOrderer);

    if (hasFiltersOrOrder === false) {
        console.warn('doesn\'t have filtersOrOrderer!')
        yield put(disableFilters(controlledFiltersKeys));
        yield put(actions.resetItems());
        yield put(actions.resetSelectedItems());
        apiPayload = yield select(selectors.getApiPayload);
    }

    yield fork(refreshFiltersValues, apiPayload)

    if (hasFiltersOrOrder) {
        console.warn('hasFiltersOrOrder TRUE, we are near to dispatch FETCH_ITEM_START')
        try {
            yield put(showLoading('itemsFetch'));

            yield put(actions.fetchItemsStart());

            const collectionEngaged = yield select(collectionsSelectors.getEngagedCollection);

            let itemsRes, collectionsRes;

            const user = yield select(userSelectors.getUser);

            const viewType = yield select(appSelectors.getCurrentViewType);

            const tableColumns =
                viewType === constants.VIEW_TYPE_COMPACT
                    ? yield select(appSelectors.getTableColumns)
                    : DEFAULT_VIEWS_TABLE_COLUMNS;

            if (collectionEngaged !== null) {

                [itemsRes, collectionsRes] = yield all([
                    // viewType === constants.VIEW_TYPE_COMPACT
                    call(fetchItemsCustom, 'foo', apiPayload, tableColumns, true),
                    // : call(fetchItemsApi, apiPayload),
                    call(getArticlesCollections, user.id, apiPayload),
                ]);
            } else {
                // if (viewType === constants.VIEW_TYPE_COMPACT) {
                itemsRes = yield call(fetchItemsCustom, 'foo', apiPayload, tableColumns, true);
                // } else {
                //     itemsRes = yield call(fetchItemsApi, apiPayload);
                // }

                collectionsRes = { data: [] };

                // console.warn('Items received => ', itemsRes.data?.total)
                /// FIX TO AVOID fuori_listino_cartaceo with sectors cause problems
                if(apiPayload.fuori_listino_cartaceo !== undefined){
                    if(itemsRes.data?.total === 0){
                        // here we reset sectors
                        const filters = ['famiglia', 'macrofamiglia', 'settore', 'classification_path', 'etim_class', 'etim_features']
                        removeFiltersFromLocalStorage(filters)
                        yield put(actions.fetchItems())
                    }
                }
            }

            // console.warn(collectionsRes);

            // console.log(itemsRes);

            yield put(actions.fetchItemsSuccess(itemsRes));
            yield put(collectionsActions.setItemsCollections(collectionsRes));
            yield put(collectionsActions.resetShownCollection());
            yield put(actions.resetSelectedItems());

            yield put(actions.setLastSearchHash(getHashFromPayload(apiPayload)));

            yield put(hideLoading('itemsFetch'));

            yield put(enableFilters(controlledFiltersKeys));

            if (options.bootstrap) {
                yield put(bootstrapAppSuccess());
            }
        } catch (err) {
            // TODO: gestire errori
            console.error(err);

            yield put(hideLoading('itemsFetch'));
            yield put(actions.fetchItemsFail(err));

            yield put(disableFilters(controlledFiltersKeys));
        }
    }else{
        console.log('non abbiamo fetchato i prodotti')
    }
}

function* fetchCollectionItemsSaga() {
    console.warn('CALLED COLLECTION ITEM SAGA')
    let collectionId = null;

    const collectionEngagedId = yield select(collectionsSelectors.getEngagedCollection);

    if (collectionEngagedId !== null) {
        collectionId = collectionEngagedId;
    } else {
        collectionId = yield select(collectionsSelectors.getShownCollection);
    }

    if (!collectionId) {
        return;
    }

    try {
        yield put(showLoading('itemsFetch'));

        yield put(actions.fetchItemsStart());

        const apiPayload = yield select(selectors.getApiPayload);

        const viewType = yield select(appSelectors.getCurrentViewType);

        let itemsRes;

        // console.log(apiPayload);

        const validApiPayloadProps = pick(apiPayload, [
            'page',
            'locale',
            'per_page',
            'sort',
            'sort_direction',
        ]);

        const fetchPayload = {
            ...validApiPayloadProps,
            collezione: collectionId,
        };

        // if (viewType === constants.VIEW_TYPE_COMPACT) {
        const tableColumns =
            viewType === constants.VIEW_TYPE_COMPACT
                ? yield select(appSelectors.getTableColumns)
                : DEFAULT_VIEWS_TABLE_COLUMNS;

        itemsRes = yield call(fetchItemsCustom, 'foo', fetchPayload, tableColumns, true);
        // } else {
        //     itemsRes = yield call(fetchItemsApi, fetchPayload);
        // }

        // const itemsRes = yield call(fetchItemsApi, {
        //     ...apiPayload,
        //     collezione: collectionId
        // });

        yield put(actions.fetchItemsSuccess(itemsRes));

        const collectionsRes = {
            data: itemsRes.data.results.reduce((c, item) => {
                c.push({
                    articolo_id: item.id,
                    collezione_ids: [collectionId],
                });

                return c;
            }, []),
        };

        yield put(collectionsActions.setItemsCollections(collectionsRes));

        yield put(hideLoading('itemsFetch'));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);

        yield put(hideLoading('itemsFetch'));
        yield put(actions.fetchItemsFail(err));
    }
}

// function* changePageSaga() {
//     const collectionEngagedId = yield select(collectionsSelectors.getEngagedCollection);

//     if (collectionEngagedId !== null) {
//         yield put(actions.fetchCollectionItems());
//     } else {
//         yield put(actions.fetchItems());
//     }
// }

function* sortSaga() {
    const isCollectionView = yield select(collectionsSelectors.getCollectionViewActive);

    if (isCollectionView) {
        yield put(actions.fetchCollectionItems());
    } else {
        yield put(actions.fetchItems());
    }
}

function* deleteItemsSaga(action) {
    const { payload } = action;

    yield all(payload.map((item) => call(removeItem, item)));
    // yield call(removeItem, payload[0]);

    toast('Articolo/i eliminati con successo', {
        position: 'bottom-right',
        type: toast.TYPE.SUCCESS,
    });

    yield put(actions.fetchItems());
}

// Function for loading items from localStorage, the main difference between the original fetchItemsSaga is that this one doesn't make check against Collection, thtat are list items user defined, useless here
function* fetchItemsFromLocalStorage(action){
    emitSagaEvent('saga_start')
    //console.warn("###### STARTING FETCH ITEMS FROM LOCAL STORAGE ######")
    yield put(showLoading('itemsFetch'));
    const options = action?.payload || {}

    let apiPayload = yield select(selectors.getApiPayload);

    //console.log('apiPayload and action from fetchItemsSaga', apiPayload, action)

    const currentHash = getHashFromPayload(apiPayload);
    const previousHash = yield select(itemsSelectors.getLastSearchHash)

    const hasFilters = yield select(selectors.isAnyFilterActive)

    //console.log('hasFilters is any filter active => ', hasFilters)

    if (hasFilters === false) {
        //console.log('Seconda if hasFilters === false')

        // LOCAL STORAGE - here we check if is stored a Classification, if isn't stored we reset items
        if (getFilterFromLocalStorage('product_tree') === null){
            yield put(actions.resetItems());
            yield put(actions.resetSelectedItems());
        }

        // e come sopra riporto l'applicazione al suo stato iniziale
        if (currentHash !== previousHash) {
            //console.log('Dentro la seconda if ma abbiamo currentHash !== previousHash')

            const currentClassification = yield select(sectorsSelectors.getFilterValue);

            const shouldRestoreSectors = currentClassification === constants.DEFAULT_CLASSIFICATION;

            // Rebuild the sectors options if we are in default classification
            if (shouldRestoreSectors === false) {
                yield fork(fetchTree);
            }
        }
        // LOCAL STORAGE: check if exist userFilters in localStorage, if isn't setted up, setup with current apiPayload value
        if(!isLocalStorageAvailable()){
            //console.log('##### non local storage available', apiPayload)
            initializeLocalStorage(apiPayload)
        }
        emitSagaEvent('saga_end')
        return
    }

    // Se l'hash della ricerca attuale è diverso (è cambiato qualche filtro) aggiorno il valore dell'hash e riporto la pagina a 1
    if (currentHash !== previousHash && options.bootstrap !== true) {

        //console.warn('FILTERS HAVE CHANGED!!!', currentHash, previousHash)
        yield put(actions.setLastSearchHash(currentHash));
    }

    // Se esistono filtro od ordinamento...
    const hasFiltersOrOrder = yield select(selectors.isAnyFilterActiveOrOrderer);

    if (hasFiltersOrOrder === false) {
        yield put(disableFilters(controlledFiltersKeys));
        yield put(actions.resetItems());
        yield put(actions.resetSelectedItems());
        apiPayload = yield select(selectors.getApiPayload);
    }

    yield call(refreshFiltersValues, apiPayload)

    if (hasFiltersOrOrder) {
        //console.warn('siamo arrivati a hasFiltersOrOrder, stiamo per iniziare FETCH_ITEM_START')
        try {


            yield put(actions.fetchItemsStart());

            let itemsRes

            const viewType = yield select(appSelectors.getCurrentViewType);

            const tableColumns =
                viewType === constants.VIEW_TYPE_COMPACT
                    ? yield select(appSelectors.getTableColumns)
                    : DEFAULT_VIEWS_TABLE_COLUMNS;

            itemsRes = yield call(fetchItemsCustom, 'foo', apiPayload, tableColumns, true);

            yield put(actions.fetchItemsSuccess(itemsRes));
            yield put(actions.resetSelectedItems());

            yield put(actions.setLastSearchHash(getHashFromPayload(apiPayload)));



            yield put(enableFilters(controlledFiltersKeys));

        } catch (err) {
            console.error(err);
            emitSagaEvent('saga_end')
            yield put(hideLoading('itemsFetch'));
            yield put(actions.fetchItemsFail(err));

            yield put(disableFilters(controlledFiltersKeys));
        }
    }else{
        console.log('non abbiamo fetchato i prodotti')
    }
    emitSagaEvent('saga_end')
}

export default [
    takeEvery(actionTypes.FETCH_ITEMS, fetchItemsSaga),
    takeEvery(actionTypes.CHANGE_ITEMS_PAGE, sortSaga),
    takeEvery(actionTypes.SORT_ITEMS_BY, sortSaga),
    takeEvery(actionTypes.FETCH_COLLECTION_ITEMS, fetchCollectionItemsSaga),
    takeEvery(CHANGE_CURRENT_TABLE_VIEW, sortSaga),
    takeEvery(actionTypes.DELETE_ITEMS, deleteItemsSaga),
    takeEvery(actionTypes.FETCH_ITEMS_FROM_LOCAL_STORAGE, fetchItemsFromLocalStorage),
];
