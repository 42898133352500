import React, { Component, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// import { fetchEtimClassDetails } from '../api';
import Loader from './utils/Loader';
import AttributeAlphanumericWrapper from './AttributeAlphanumericWrapper';
import AttributeLogicWrapper from './AttributeLogicWrapper';
import AttributeNumericWrapper from './AttributeNumericWrapper';
// import AttributeRangeWrapper from './AttributeRangeWrapper';
import * as c from '../constants';
import { getLocaleFromLanguageCode } from '../intl-helpers'
import { getFilterFromLocalStorage } from '../utils/LocalStorageFilterUtils'

// Connect to the redux store and get the pageState reducer properties
import { connect } from 'react-redux';
import { getFromInternalPage } from '../reducers/pageStateReducer';
import { delay } from 'redux-saga';


class ProductClassAttributeFilters extends Component {
    state = {
        isFetchingEtimClassDetails: false,
        etimClassDetails: {},
        alreadySetted: {}
    }

    componentDidMount() {
        this.setState({ alreadySetted: {} })
    }
    async componentDidUpdate(prevProps) {
        if (prevProps.etimClassId !== this.props.etimClassId && this.props.etimClassId !== null) {
            this.setState({
                isFetchingEtimClassDetails: true,
            });

            // TODO: necessario?
            // const res = await fetchEtimClassDetails(this.props.etimClassId);

            this.setState({
                isFetchingEtimClassDetails: false,
                // etimClassDetails: res.data
            });
        }

        // Call the method that get the localStorage etim_features, check if the current rendered filter is stored in localStorage, it true
        // dispatch the method to set the local stored value
        // this.checkAndFetchFromLocalStorage(attribute)
        /*if (prevProps.attributes !== this.props.attributes) {
            this.props.attributes.forEach(attribute => {
                this.checkAndFetchFromLocalStorage(attribute, this.props.fromInternalPage);
            });
        }*/
    }


    // checkAndFetchFromLocalStorage = (attribute, fromInternalPage) => {
    //     const { alreadySetted } = this.state
    //     const { onFetchProductClassAttributeData, onCheckboxToggle } = this.props;
    //     const storedEtimFeatures = getFilterFromLocalStorage('etim_features') || {};
    //     if(alreadySetted){
    //         //console.log('alreadySetted', alreadySetted)
    //     }
    //     if(attribute === undefined) return
    //     if(attribute?.key === undefined) return
    //     if(storedEtimFeatures === undefined) return
    //     if(storedEtimFeatures[attribute.key] === undefined) return
    //     if(alreadySetted[attribute.key]) return
    //
    //     // Verifica se l'attributo corrente è presente in 'etim_features'
    //     const storedValue = storedEtimFeatures[attribute.key]
    //
    //     if(storedValue === undefined || storedValue?.value === undefined) return
    //     // Chiama la funzione di caricamento con il valore dal localStorage
    //     onFetchProductClassAttributeData(attribute.key)
    //     storedValue.value.forEach((value, key) => {
    //         console.log('now i\' toogling the button')
    //         //!fromInternalPage && onCheckboxToggle(attribute.key, value)
    //         /*setTimeout(() => {
    //             onCheckboxToggle(attribute.key, value)
    //         }, 500)*/
    //         onCheckboxToggle(attribute.key, value)
    //
    //     })
    //
    //     this.setState((prevState) => ({
    //         alreadySetted: { ...prevState.alreadySetted, [attribute.key]: true },
    //     }))
    //
    // }

    renderAttributeControl(attribute) {

        // console.log("Attribute Control", attribute?.toJS())

        const {
            activeFilters,
            onAttributeResetClick,
            onCheckboxToggle,
            onFetchProductClassAttributeData,
            language,
        } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        const um = get(
            this.state.etimClassDetails,
            `etim_features.${attribute.key}.etim_unit_of_measure`
        );

        if (um && um.descrizione) {
            attribute = attribute.set('label', `${attribute.label} [${um.descrizione}]`);
        }

        const label = attribute.label[locale];

        switch (attribute.type) {
            case c.ATTRIBUTE_TYPE_ALPHANUMERIC:
            default:
                return (
                    <AttributeAlphanumericWrapper
                        attribute={attribute}
                        label={label}
                        language={language}
                        activeFilters={activeFilters.get(attribute.key)}
                        onAttributeResetClick={onAttributeResetClick}
                        onCheckboxToggle={onCheckboxToggle}
                        onLoad={onFetchProductClassAttributeData}
                    />
                );
            case c.ATTRIBUTE_TYPE_LOGIC:
                return (
                    <AttributeLogicWrapper
                        attribute={attribute}
                        label={label}
                        value={activeFilters.get(attribute.key) || 'all'}
                        onChange={this.props.onChangeBooleanField}
                        onLoad={onFetchProductClassAttributeData}
                    />
                );
            case c.ATTRIBUTE_TYPE_NUMERIC:
                // console.log(attribute.data);
                const min = attribute.data ? attribute.data.min : 0;
                const max = attribute.data ? attribute.data.max : 0;
                const isActive = activeFilters.has(attribute.key);
                const active = activeFilters.get(attribute.key);

                const start = isActive ? active[0] : min;
                const end = isActive ? active[1] : max;

                return (
                    <AttributeNumericWrapper
                        attribute={attribute}
                        label={label}
                        min={min}
                        max={max}
                        start={start}
                        end={end}
                        isActive={isActive}
                        onChange={this.props.onChangeNumberField}
                        onLoad={onFetchProductClassAttributeData}
                        onClear={onAttributeResetClick}
                    />
                );
            // case c.ATTRIBUTE_TYPE_RANGE:
            //     // return <div />;

            //     return (
            //         <AttributeRangeWrapper
            //             attribute={attribute}
            //             activeFilters={activeFilters.get(attribute.key)}
            //             onLoad={onFetchProductClassAttributeData}
            //             onAttributeResetClick={onAttributeResetClick}
            //             onCheckboxToggle={onCheckboxToggle}
            //             // onClear={onAttributeResetClick}
            //             // onChange={this.props.onChangeRangeField}
            //         />
            //     );
        }
    }

    render() {
        const { attributes, mainLoaderShowing } = this.props;
        const { isFetchingEtimClassDetails } = this.state;

        if (isFetchingEtimClassDetails) {
            if (mainLoaderShowing) {
                return null;
            }
            return (
                <div className="col-span-12">
                    <Loader />
                </div>
            );
        }

        return (
            <Fragment>
                {attributes
                    // .filter(a => a.type !== c.ATTRIBUTE_TYPE_RANGE)
                    .sortBy((a) => a.label)
                    .map((attribute) => (
                        <div className="col-span-6 lg:col-span-3" key={attribute.key}>
                            {this.renderAttributeControl(attribute)}
                            {/* <span className="text-uppercase">
                                    {attribute.label}
                                </span>

                                <div>
                                    {activeFilters.has(attribute.key) && (
                                        <Trans
                                            className="text-primary"
                                            id="filter:active:n"
                                            values={{
                                                count: activeFilters
                                                    .get(attribute.key)
                                                    .count()
                                            }}
                                        />
                                    )}
                                </div>

                                <ProductClassAttributeItem
                                    attribute={attribute}
                                    activeFilters={activeFilters.get(attribute.key)}
                                    onAttributeResetClick={onAttributeResetClick}
                                    onCheckboxToggle={onCheckboxToggle}
                                /> */}
                        </div>
                    ))}
            </Fragment>
        );
    }
}

ProductClassAttributeFilters.propTypes = {
    attributes: PropTypes.object,
    activeFilters: PropTypes.object,
    onAttributeResetClick: PropTypes.func.isRequired,
    onCheckboxToggle: PropTypes.func.isRequired,
    onChangeBooleanField: PropTypes.func,
    onChangeNumberField: PropTypes.func,
    onFetchProductClassAttributeData: PropTypes.func,
    mainLoaderShowing: PropTypes.bool,
};

ProductClassAttributeFilters.defaultProps = {
    mainLoaderShowing: true,
};

const mapStateToProps = (state) => ({
    fromInternalPage: getFromInternalPage(state),  // Ottieni il valore del selector
})

export default connect(mapStateToProps)(ProductClassAttributeFilters)
