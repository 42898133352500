import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import { selectors } from '../reducers/filtersReducer';
import { selectors as productClassReducerSelectors } from '../reducers/productClassesReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import * as constants from '../constants';
import { actions, setFilterValue } from '../actions/filtersActions';
import {
    actions as sectorsActions,
    setClassificationValue,
    toggleClass,
    toggleTreeItem
} from '../actions/sectorsActions';
import {
    updateLocalStorage,
    getFilterFromLocalStorage,
    removeFilterFromLocalStorage,
    updateLocalStorageStringValue
} from '../utils/LocalStorageFilterUtils';
import { actions as productClassesActions } from '../actions/productClassesActions';
import { getFromInternalPage } from '../reducers/pageStateReducer'
import { setIsLoadingFromLocalStorage } from '../actions/pageStateActions'

function getValidFilterOptions(node) {
    if (node === null) {
        return null;
    }

    if (node.children.size === 0) {
        return null;
    }

    return node.children.map((l) => l.id);
}

export class SidebarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtersApplied: {
                status: false,
                line: false,
                model: false,
                series: false,
                listini: false,
                classification: false,
                classification_path: false,
                etim_class: false
            },
            userHasChanged: {
                status: false,
                line: false,
                model: false,
                series: false,
                listini: false,
                classification: false,
                classification_path: false,
                etim_class: false
            }
        }
    }
    // Handle loading the 'fuori_listino_cartaceo' filter from local storage in the componentDidMount event. If the filter is present, it is set and the related SET_FILTER_VALUE action is dispatched.
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // Handling the various filters

        const filterMapping = {
            statusFilter: 'stato',
            lineFilter: 'linea',
            modelFilter: 'modello',
            seriesFilter: 'serie',
            listiniFilter: 'listini',
        }

        Object.keys(filterMapping).forEach((filterKey) => {
            const filterAppliedKey = filterKey.replace('Filter', '')

            if (this.state.userHasChanged[filterAppliedKey]) {
                return;  // User changed manually the filter, exit.
            }

            // Check if the filter is change or have some options.
            if (prevProps[filterKey] !== this.props[filterKey] && this.props[filterKey].toJS().options && !this.state.filtersApplied[filterAppliedKey]) {
                const storedFilters = JSON.parse(localStorage.getItem('userFilters'));

                if (!storedFilters || !storedFilters[filterMapping[filterKey]]) {
                    return;  // Return it there aren't stored filters
                }

                const filterFromStorage = storedFilters[filterMapping[filterKey]];
                const filterProperties = this.props[filterKey].toJS();

                filterFromStorage.forEach(storedValue => {
                    const matchedOption = filterProperties.options.find(option => option.value === storedValue);
                    if (matchedOption) {
                        matchedOption.enabled = true

                        //if(!this.props.fromInternalPage){
                            const filterConstant = constants[`${filterKey.replace('Filter', '').toUpperCase()}_FILTER`];
                            this.props.dispatch(setFilterValue(filterConstant, matchedOption));

                        //}

                    }
                });

                // Set the state to avoid others filters updates.
                this.setState((prevState) => ({
                    filtersApplied: {
                        ...prevState.filtersApplied,
                        [filterKey.replace('Filter', '')]: true
                    }
                }));
            }
        })

        // Handling the Classifications
        const classificationMapping = {
            classificationFilter: 'product_tree',
        }

        Object.keys(classificationMapping).forEach((filterKey) => {
            const filterAppliedKey = filterKey.replace('Filter', '');

            if (this.state.userHasChanged[filterAppliedKey]) {
                return  // User changed manually the filter, exit.
            }

            if (prevProps[filterKey] !== this.props[filterKey] && this.props[filterKey].toJS().options && !this.state.filtersApplied[filterAppliedKey]) {
                const storedFilters = JSON.parse(localStorage.getItem('userFilters'));

                if (!storedFilters || !storedFilters[filterMapping[filterKey]]) {
                    return;  // Return it there aren't stored filters
                }

            }
        })

        // Here we write the code for classification_path, to set the state of classification path: is the selectedItem in SectorsTree
        const classificationPath = getFilterFromLocalStorage('classification_path')
        // console.log('this.state.userHasChanged.classification_path', this.state.userHasChanged.classification_path)

        // Early return if user changed the classification_path or if it's already applied
        if (this.state.userHasChanged.classification_path || this.state.filtersApplied.classification_path) {
            return;
        }
        // if (classificationPath && !this.state.filtersApplied.classification_path) {
        // if (classificationPath) {
        //
        //     //if(!this.props.fromInternalPage) {
        //         this.props.dispatch(toggleTreeItem(classificationPath, 0))
        //     //}
        //
        //     // Update state
        //     this.setState((prevState) => ({
        //         filtersApplied: {
        //             ...prevState.filtersApplied,
        //             classification_path: true,  // Set filter as already applied
        //         },
        //         userHasChanged: {
        //             ...prevState.userHasChanged,
        //             classification_path: false  // We have loaded filter from local storage, so isnt' setted manually by user, userHasChanged need to be set to false
        //         }
        //     }));
        // }

        // Handling the ETIM class
        const etimClass = getFilterFromLocalStorage('etim_class')

        if(this.state.userHasChanged.etim_class){
            // console.log('userHasChanged.etim_class')
        }

        // Early return if user changed the etim_class or if it's already applied
        if (this.state.userHasChanged.etim_class || this.state.filtersApplied.etim_class) {
            return
        }


        if (etimClass) {
            //if(!this.props.fromInternalPage) {
                const filterProperties = this.props['productClassFilter']?.toJS()
                const matchedOption = filterProperties.options.find(option => option.value === etimClass)
                // console.log('filterProperties => ', filterProperties)
                // console.log('matchedOption => ', matchedOption)
                //this.props.dispatch(productClassesActions.toggleProductClassAttributeFilter(etimClass))
                this.props.dispatch(productClassesActions.setProductClassValue(matchedOption))
            //}

            // Update state
            this.setState((prevState) => ({
                filtersApplied: {
                    ...prevState.filtersApplied,
                    etim_class: true,  // Set filter as already applied
                },
                userHasChanged: {
                    ...prevState.userHasChanged,
                    etim_class: false  // We have loaded filter from local storage, so isnt' setted manually by user, userHasChanged need to be set to false
                }
            }));

        }
    }

    handleFilterChange = (filterKey, selectedFilter) => {
        console.warn('handleFilterChange', filterKey, selectedFilter)
        this.props.dispatch(setIsLoadingFromLocalStorage(false))

        if (selectedFilter === null) {
            removeFilterFromLocalStorage(filterKey)
            this.updateStateAndDispatch(filterKey, null)
            return
        }

        const currentStoredFilter = getFilterFromLocalStorage(filterKey);
        // console.log('currentStoredFilter', currentStoredFilter, filterKey)
        const action = currentStoredFilter && currentStoredFilter.includes(selectedFilter.value)
            ? 'remove'
            : 'add'

        if(filterKey === 'classification' || filterKey === 'classification_path' || filterKey === 'etim_class' || filterKey === 'fuoriCartaceo') {
            updateLocalStorageStringValue(filterKey, selectedFilter.value, action)
        }else{
            updateLocalStorage(filterKey, selectedFilter.value, action)
        }

        if(filterKey === 'classification'){
            updateLocalStorageStringValue('classification_path', '', 'remove')
        }

        this.updateStateAndDispatch(filterKey, selectedFilter)
    }

    // Helper function to update State and dispatch action
    updateStateAndDispatch = (filterKey, selectedFilter) => {
        this.setState((prevState) => ({
            filtersApplied: {
                ...prevState.filtersApplied,
                [filterKey]: false,  // Reset when user manually changes the filter
            },
            userHasChanged: {
                ...prevState.userHasChanged,
                [filterKey]: true,  // Mark as manually changed
            }
        }))

        if(filterKey === 'fuoriCartaceo'){
            this.props.dispatch(setFilterValue(constants.FUORI_CARTACEO_FILTER, selectedFilter))
            return
        }
        if(filterKey === 'classification'){
            this.props.dispatch(setClassificationValue(selectedFilter))
            return
        }

        if (filterKey === 'classification_path') {
            this.props.dispatch(toggleTreeItem(selectedFilter.id, selectedFilter.depth))
            return
        }

        if(filterKey === 'etim_class') {

            if(!selectedFilter){
                this.props.dispatch(productClassesActions.setProductClassValue(selectedFilter))
                return
            }
            const filterProperties = this.props['productClassFilter']?.toJS()

            const matchedOption = filterProperties.options.find(option => option.value === selectedFilter.id)
            selectedFilter?.value ? this.props.dispatch(productClassesActions.setProductClassValue(selectedFilter)) : this.props.dispatch(productClassesActions.setProductClassValue(matchedOption))
            // this.props.dispatch(productClassesActions.setProductClassValue(matchedOption))
            return
        }

        const filterConstant = constants[`${filterKey.toUpperCase()}_FILTER`]
        this.props.dispatch(setFilterValue(filterConstant, selectedFilter))
    }


    render() {
        const filterMapping = {
            onStatusChange: 'status',
            onFuoriCartaceoChange: 'fuoriCartaceo',
            onLineChange: 'line',
            onModelChange: 'model',
            onSeriesChange: 'series',
            onListiniChange: 'listini',
            onClassificationChange: 'classification',
            onClassificationPathChange: 'classification_path',
            onEtimClassChange: 'etim_class',
        }

        const sidebarProps = Object.keys(filterMapping).reduce((acc, eventKey) => {
            const filterKey = filterMapping[eventKey];
            acc[eventKey] = (selectedFilter) => this.handleFilterChange(filterKey, selectedFilter)
            return acc;
        }, {})

        return <Sidebar {...this.props} {...sidebarProps} />
    }
}

function mapStateToProps(state) {
    const productProperties = appSelectors.getProductProperties(state);

    const classificationType = sectorsSelectors.getFilterValue(state);
    let selectedNode = null;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        selectedNode = sectorsSelectors.getSelectedTreeItemRecord(state);
    }

    // console.log('selectors.getFilter(state, constants.LINE_FILTER),', selectors.getFilter(state, constants.LINE_FILTER).toJS())

    return {
        classificationFilter: sectorsSelectors.getFilter(state),
        brandFilter: selectors.getFilter(state, constants.BRAND_FILTER),
        // listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        // optionalsFilter: selectors.getFilter(state, constants.OPTIONALS_FILTER),
        priceRangeFilter: selectors.getFilter(state, constants.PRICE_RANGE_FILTER),
        isBrandFixed: appSelectors.getIsBrandFixed(state),
        language: appSelectors.getLanguage(state),
        showPriceRangeFilter: !!productProperties.prezzo_listino,
        statusFilter: selectors.getFilter(state, constants.STATUS_FILTER),
        lineFilter: selectors.getFilter(state, constants.LINE_FILTER),
        modelFilter: selectors.getFilter(state, constants.MODEL_FILTER),
        seriesFilter: selectors.getFilter(state, constants.SERIES_FILTER),
        listiniFilter: selectors.getFilter(state, constants.LISTINI_FILTER),
        fuoriCartaceoFilter: selectors.getFilter(state, constants.FUORI_CARTACEO_FILTER),
        esportabilePerCatalogoFilter: selectors.getFilter(
            state,
            constants.ESPORTABILE_PER_CATALOGO_FILTER
        ),
        productClassFilter: productClassReducerSelectors.getFilter(state),
        //selectedItem: sectorsSelectors.getSelectedItem(state),
        enableEsportabilePerCatalogoFilter:
            appSelectors.getEnableEsportabilePerCatalogoFilter(state),
        validFilterOptions: getValidFilterOptions(selectedNode),
        fromInternalPage: getFromInternalPage(state),
    };
}

export default connect(mapStateToProps)(SidebarContainer);
