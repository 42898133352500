import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Trans /*, NumberFormat*/ } from '@lingui/react';
import { Portal } from 'react-portal';
import { NavLink, withRouter } from 'react-router-dom';
import { FaList } from 'react-icons/fa';
import cx from 'classnames';

import ListiniModal from './ListiniModal';
import { getIntlNumberFormat } from '../intl-helpers';
import { VIEW_TYPE_COMPACT, VIEW_TYPE_DETAILED, VIEW_TYPE_BLOCK } from '../constants';
import { setFromInternalPage } from '../actions/pageStateActions'

const intl = getIntlNumberFormat();

class ArticleTableHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showListiniModal: false,
        };
    }

    render() {
        const {
            viewType,
            setViewType,
            total,
            displayArticleNumber,
            showCollectionBtn,
            // isCollectionsShowed,
            listiniFilter,
            brandFilter,
            location,
        } = this.props;

        // TODO: bug in visualizzazione traduzione, con numeri maggiori di 1.000, le cifre dopo il . vengono interpretate
        // come decimali e quindi tagliate a 2, indagare...
        return (
            <div className="grid grid-cols-12">
                <div className="col-span-12 md:col-span-8 lg:col-span-10">
                    <h2 className="text-primary">
                        {displayArticleNumber && (
                            <Fragment>
                                {total < 2 ? (
                                    <Trans id="article:count" values={{ count: total }} />
                                ) : (
                                    <span>
                                        {intl.format(total)} <Trans id="articles" />
                                    </span>
                                )}
                            </Fragment>
                        )}
                        <button
                            className={cx('!hidden lg:!inline-block btn btn-sm text-uppercase', {
                                'btn-secondary': viewType === VIEW_TYPE_COMPACT,
                                'btn-link': viewType !== VIEW_TYPE_COMPACT,
                                'lg:ml-2': total > 0,
                            })}
                            onClick={() => setViewType(VIEW_TYPE_COMPACT)}
                        >
                            <i className="icon icon-menu" /> <Trans id="view:compact" />
                        </button>
                        <button
                            className={`!hidden lg:!inline-block btn btn-sm btn-${
                                viewType === VIEW_TYPE_DETAILED ? 'secondary' : 'link'
                            } text-uppercase btn-detailed`}
                            onClick={() => setViewType(VIEW_TYPE_DETAILED)}
                        >
                            <i className="icon icon-apps" /> <Trans id="view:detailed" />
                        </button>
                        <button
                            className={`!hidden lg:!inline-block btn btn-sm btn-${
                                viewType === VIEW_TYPE_BLOCK ? 'secondary' : 'link'
                            } text-uppercase`}
                            onClick={() => setViewType(VIEW_TYPE_BLOCK)}
                        >
                            <i className="icon icon-photo" /> <Trans id="view:block" />
                        </button>
                    </h2>
                </div>
                <div className="col-span-12 md:col-span-4 lg:col-span-2 text-right flex justify-start md:justify-end mb-1 md:mb-0">
                    {brandFilter.value.count() === 1 && listiniFilter.options.count() > 0 && location.pathname !== '/app/lists' && (
                        <button
                            className="btn btn-sm text-uppercase !flex items-center mr-1"
                            onClick={() => this.setState({ showListiniModal: true })}
                        >
                            <span className="lg:mr-2">
                                <i className="icon icon-link" />
                            </span>
                            <span className='hidden lg:block'>
                                <Trans id="show lists" />
                            </span>
                        </button>
                    )}
                    {this.state.showListiniModal && (
                        <Portal>
                            <ListiniModal
                                brand={brandFilter.toJS()}
                                listini={listiniFilter.toJS()}
                                onClose={() => this.setState({ showListiniModal: false })}
                            />
                        </Portal>
                    )}
                    {showCollectionBtn && location.pathname !== '/app/lists' && (
                        <NavLink
                            className="btn btn-sm btn-collections text-uppercase !flex items-center lg:!px-2"
                            to="/app/lists"
                            onClick={() => {
                                this.props.setFromInternalPage(true)
                            }}
                        >
                            <span className="lg:mr-2">
                                <FaList />
                            </span>
                            <span className='hidden lg:block'>
                                <Trans id="collections" />
                            </span>
                        </NavLink>
                    )}
                </div>
            </div>
        );
    }
}

ArticleTableHeader.propTypes = {
    viewType: PropTypes.string,
    setViewType: PropTypes.func,
    total: PropTypes.number,
    displayArticleNumber: PropTypes.bool,
    onCollectionsBtnClick: PropTypes.func,
    showCollectionBtn: PropTypes.bool,
};

ArticleTableHeader.defaultProps = {
    showCollectionBtn: true,
};

function mapDispatchToProps(dispatch){
    return {
        setFromInternalPage(value) {
            dispatch(setFromInternalPage(value))
        }
    }
}
export default connect(null, mapDispatchToProps)(withRouter(ArticleTableHeader))
