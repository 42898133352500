import { put, take, race, delay } from 'redux-saga/effects';
import { sagaCompletionChannel } from './sharedChannel';
import { setIsLoadingFromLocalStorage } from '../actions/pageStateActions';
import { resetSagas } from '../actions/sagaStatusActions';
import { hideLoading } from 'react-redux-loading-bar';

export function* monitorSagaCompletion() {
    while (true) {
        let activeSagas = 0

        while (true) {
            // Use `race` to handle `sagaCompletionChannel` and `resetSagas` actions concurrently
            const { channelAction, resetAction } = yield race({
                channelAction: take(sagaCompletionChannel),
                resetAction: take(resetSagas().type)
            })

            if (resetAction) {
                console.log('Resetting saga monitoring')
                activeSagas = 0
                break // Exit inner loop and restart monitoring cycle
            }

            if (channelAction) {
                //console.log('channelAction', channelAction)
                if (channelAction === 'saga_start') activeSagas++
                if (channelAction === 'saga_end') activeSagas--

                if (activeSagas === 0) {
                    console.log('All sagas have completed')
                    yield put(hideLoading('itemsFetch'))
                    yield put(setIsLoadingFromLocalStorage(false))
                    break // Exit inner loop after sagas complete
                }
            }
        }
    }
}
