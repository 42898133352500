
const types = {
    SET_FROM_INTERNAL_PAGE: 'SET_FROM_INTERNAL_PAGE',
    RESET_FROM_INTERNAL_PAGE: 'RESET_FROM_INTERNAL_PAGE',
    IS_LOADING_FROM_LOCAL_STORAGE: 'IS_LOADING_FROM_LOCAL_STORAGE',
    SET_LOADED_FROM_OTHERS_PAGES: 'SET_LOADED_FROM_OTHERS_PAGES',
    IS_CHANGING_CLASSIFICATION: 'IS_CHANGING_CLASSIFICATION',
}

export default types

export function setFromInternalPage(value) {
    return {
        type: types.SET_FROM_INTERNAL_PAGE,
        payload: value,
    }
}
export function setIsLoadingFromLocalStorage(value){
    return {
        type: types.IS_LOADING_FROM_LOCAL_STORAGE,
        payload: value
    }
}
export function setIsChangingClassification(value){
    return {
        type: types.IS_CHANGING_CLASSIFICATION,
        payload: value
    }
}
export function resetFromInternalPage() {
    return {
        type: types.RESET_FROM_INTERNAL_PAGE
    }
}
export function setLoadedFromOthersPages(value){
    return {
        type: types.SET_LOADED_FROM_OTHERS_PAGES,
        payload: value
    }
}