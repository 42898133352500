// classificationMiddleware.js
const classificationMiddleware = (store) => (next) => (action) => {
    if (action.type === 'SET_CLASSIFICATION_VALUE') {
        // Get the previous classification value from the state
        const state = store.getState();
        const previousClassification = state.sectors.getIn(['classificationFilter', 'value']);

        // Attach previousClassification to action.meta
        const newAction = {
            ...action,
            meta: {
                ...action.meta,
                previousClassification,
            },
        };

        // Pass the action to the next middleware/reducer
        return next(newAction);
    } else {
        // For other actions, just pass them along
        return next(action);
    }
};

export default classificationMiddleware;
