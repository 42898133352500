import { all, call, put, select, take, takeEvery, fork, race } from 'redux-saga/effects';
import { sagaCompletionChannel } from './sharedChannel';
import { emitSagaEvent } from './sharedChannel'
import omit from 'lodash/omit';
// import includes from 'lodash/includes';

import {
    actions as filtersActions,
    actions,
    actionTypes,
    resetAllFilters, resetLocalStorageFilters,
    SET_FILTER_OPTIONS
} from '../actions/filtersActions';
import {
    actions as productClassesActions,
    fetchProductClassAttributeData,
    fetchProductClassAttributeDataStart,
    fetchProductClassAttributes, fetchProductClassAttributesSuccess,
    setProductClassAttributeLogic, setProductClassAttributeNumeric,
    toggleProductClassAttributeFilter
} from '../actions/productClassesActions';
import { fetchItems, fetchItemsFromLocalStorage } from '../actions/itemsActions';
import { actions as sectorActions, setClassificationValue, toggleTreeItem } from '../actions/sectorsActions';
// import { setTree } from '../actions/sectorsActions';
import { selectors as  selectors } from '../reducers/filtersReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
import { fetchTree } from './sectorsSaga';

import * as api from '../api';
import * as constants from '../constants';

import {
    updateLocalStorage,
    getFilterFromLocalStorage,
    removeFilterFromLocalStorage,
    removeBrandRelatedFilterFromLocalStorage, isLocalStorageAvailable, getStoredFilters
} from '../utils/LocalStorageFilterUtils';
import { setIsLoadingFromLocalStorage } from '../actions/pageStateActions';
import { store } from '../index';
import { delay } from 'redux-saga';
import { getIsChangingClassification, getIsLoadingFromLocalStorage } from '../reducers/pageStateReducer';
import {selectors as filterSelectors} from '../reducers/filtersReducer'
import { fetchEtimFeatures } from '../api';
import { SECTION_TYPE_FEATURES } from '../constants';

let isBatchMode = false

// const resetProductClassesKeys = [
//     constants.FULL_TEXT_FILTER,
//     constants.CATALOG_FILTER,
//     constants.LINE_FILTER,
//     constants.MODEL_FILTER,
//     constants.SERIES_FILTER
// ];

export const brandDependentFilters = [
    // constants.CATALOG_FILTER,
    constants.LINE_FILTER,
    constants.MODEL_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
];

function* setFilterValueSaga(action) {
    // const { key } = action.payload;

    // if (includes(resetProductClassesKeys, key)) {
    //     yield put(productClassesActions.setProductClassValue(null));
    // }
    const isChangingClassification = yield select(getIsChangingClassification)
    //console.warn('is changing classification ? => ', isChangingClassification)
    if (!isBatchMode && !isChangingClassification) {
        //console.warn("isnt batch mode")

        //yield delay(1500)
        yield put(fetchItems()) // Only fetch items if not in batch mode
    }

    // yield put(fetchItems());
}

function* changeBrandFilterSaga(action) {
    const actualBrandFilter = yield select(selectors.getFilter, constants.BRAND_FILTER);

    const actualBrandFilterValue = actualBrandFilter?.value?.toJS() || [];

    let isValueInFilter = false;

    if (action.payload !== null) {
        isValueInFilter = actualBrandFilterValue.some(
            (filter) => filter?.value === action.payload.value
        )
    }

    // Case 1: The value is not present, add it and reset dependent filters
    if (!isValueInFilter && action.payload !== null) {
        //console.log("The value is not present, resetting brand-dependent filters");
        removeBrandRelatedFilterFromLocalStorage()
        yield put(actions.resetFilters(brandDependentFilters))

        // Case 2: The value is already present and is the only element: deselecting the last active filter
    } else if (actualBrandFilterValue.length === 1 && isValueInFilter || action.payload === null) {
        //console.log("The value is present and is the only one, resetting brand-dependent filters");
        yield put(actions.resetFilters(brandDependentFilters));
        yield put(filtersActions.resetAllFilters());
        yield put(actions.setFilterValue(constants.BRAND_FILTER, null));
        removeBrandRelatedFilterFromLocalStorage()
        removeFilterFromLocalStorage('brand')
        refreshFiltersValues()
        // console.log('apiPayload', action.payload);
        return;

        // Case 3: The value is present but is not the only one, toggle without resetting
    } else {
        // console.log("The value is present, acting as a toggle without reset");
    }

    yield put(actions.setFilterValue(constants.BRAND_FILTER, action.payload));

    const updateFilterAction =
        action.payload === null ? actions.disableFilters : actions.enableFilters;

    yield put(updateFilterAction(brandDependentFilters))
    const apiPayload = yield select(selectors.getApiPayload)

    const shouldFetchFilters = (actualBrandFilterValue.length === 0) ||
        (actualBrandFilterValue.length === 2 && apiPayload.sigle_marca.toJS().length === 1)

    //console.log('actualBrandFilterValue', actualBrandFilterValue, apiPayload.sigle_marca.toJS())

    if (shouldFetchFilters) {
        //console.log('find if we have only one brand', action.payload, actualBrandFilterValue)

        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER)
        ])
        // refreshFiltersValues()
    }
    const isLoadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)

    if(apiPayload.etim_class !== undefined && !isLoadingFromLocalStorage) {
        yield call(fetchFilterData, api.fetchEtimFeatures, apiPayload, 'etim_features')
    }

}


export function* refreshFiltersValues(apiPayload = null) {
    emitSagaEvent('saga_start')
    //console.log('refreshFiltersValues')
    //console.log('apiPayload prima di fetchItemsCustom:', apiPayload);
    const isLoadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)
    if (apiPayload === null) {
        apiPayload = yield select(selectors.getApiPayload);
    }

    //console.log('apiPayload from refreshFiltersValues => ', apiPayload)

    yield fork(fetchTree);

    const brandApiPayload = omit(apiPayload, ['sigle_marca']);

    const storedFilters = JSON.parse(localStorage.getItem('userFilters'))
    const storedStato = storedFilters?.stato || []
    // Define statusPayload
    let statusPayload;

    const [brandsRes, statusRes, priceRangeRes, etimClassRes, listiniRes] = yield all([
        call(api.fetchBrandFilter, brandApiPayload),
        call(api.fetchStatusFilter, apiPayload),
        call(api.fetchPriceRangeFilter, apiPayload),
        call(api.fetchEtimClassFilter, apiPayload),
        call(api.fetchListini, apiPayload),
        // call(api.fetchClassificationTree, apiPayload)
    ]);

    yield put(actions.setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
    yield put(actions.setFilterOptions(constants.STATUS_FILTER, statusRes.data));
    yield put(actions.setPriceRange(priceRangeRes.data));
    yield put(productClassesActions.setProductClassOptions(etimClassRes.data));
    yield put(actions.setFilterOptions(constants.LISTINI_FILTER, listiniRes.data));
    // yield put(setTree(treeRes.data))

    // console.log('apiPayload.sigle_marca is not empty => ', apiPayload);

    const sigleMarca = apiPayload.sigle_marca
    const shouldFetch = sigleMarca !== undefined && (sigleMarca.length === 1 || sigleMarca.toJS().length === 1)

    if (shouldFetch) {
        yield all([
            call(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER),
            call(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER),
            call(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER),
            call(fetchFilterData, api.fetchListini, apiPayload, constants.LISTINI_FILTER)
        ])
    }

    if(apiPayload.etim_class !== undefined && !isLoadingFromLocalStorage){
        //console.warn('abbiamo una etim class e un brand')
        //console.warn('apiPayload => ', apiPayload)
        //yield call(fetchFilterData, api.fetchEtimFeatures, apiPayload, 'etim_features')
    }
    emitSagaEvent('saga_end')

}

function* fetchFilterData(apiFn, apiPayload, filterName) {
    try {
        const res = yield call(apiFn, apiPayload);

        if(filterName === 'etim_features'){
            yield put(fetchProductClassAttributesSuccess(res))
        }else{
            yield put(actions.setFilterOptions(filterName, res.data));
        }

    } catch (err) {
        console.error(err);
    }
}

function* loadFiltersFromLocalStorage(){
    console.log('Loading filters  from local storage')
    if (!isLocalStorageAvailable()) {
        yield put(setIsLoadingFromLocalStorage(false))
        //console.log('no userFilters in localStorage')
        return
    }

    emitSagaEvent('saga_start')
    yield put(setIsLoadingFromLocalStorage(true))

    const storedFilters = getStoredFilters()
    //console.log(storedFilters)
    // Start batch mode
    isBatchMode = true
    // Check for stored filters and dispatch the corresponding actions
    yield all([
        call(handleBrandFilters, storedFilters),
        call(handleClassificationFilters, storedFilters),
        call(handleSectorFilters, storedFilters),
        call(handleEtimFilters, storedFilters),
        call(handleEtimAttributesFilters, storedFilters),
        call(handleFuoriCartaceoFilters, storedFilters),
        call(handlePriceRangeFilters, storedFilters),
    ])

    // End batch mode
    isBatchMode = false
    // Once all filters are loaded, we dispatch fetchItems and finish
    yield put(fetchItemsFromLocalStorage(0))


    // // Set isLoadingFromLocalStorage to false because all sagas are loeaded
    // yield put(setIsLoadingFromLocalStorage(false))
    emitSagaEvent('saga_end')
}

function* handleBrandFilters(storedFilters){
    emitSagaEvent('saga_start')
    const storedBrandCodes = storedFilters.sigle_marca || []

    // Get the current filters from Redux State
    const brandFilters = yield select(selectors.getFilter, constants.BRAND_FILTER)
    // Convert the options into JS array to easily make a check
    const brandOptions = brandFilters.get('options').toJS()
    // Iterate over brand filter values and select the existing ones
    const selectedBrands = brandOptions.filter(option => storedBrandCodes.includes(option.code))

    if (selectedBrands.length > 0) {
        for (const brand of selectedBrands) {
            yield put(actions.setFilterValue(constants.BRAND_FILTER, brand));
        }

        if (selectedBrands.length === 1) {
            yield all([
                call(fetchFilterData, api.fetchLineeFilter, storedFilters, constants.LINE_FILTER),
                call(fetchFilterData, api.fetchModelliFilter, storedFilters, constants.MODEL_FILTER),
                call(fetchFilterData, api.fetchSeriesFilter, storedFilters, constants.SERIES_FILTER),
                call(fetchFilterData, api.fetchListini, storedFilters, constants.LISTINI_FILTER)
            ]);

            yield put(actions.enableFilters(brandDependentFilters));
        }
    }
    emitSagaEvent('saga_end')
}

function* handleClassificationFilters(storedFilters) {
    emitSagaEvent('saga_start')
    const storedClassification = storedFilters.product_tree || false;
    if (storedClassification) {
        const classificationFilter = yield select(sectorsSelectors.getFilter);
        const classificationList = classificationFilter.toJS().options;

        const selectedClassification = classificationList.find(option => option.value === storedClassification);
        yield put(setClassificationValue(selectedClassification));
    }
    emitSagaEvent('saga_end')
}

// handleSectorFilters is loading all filters managed by sectorsReducer
function* handleSectorFilters(storedFilters) {
    emitSagaEvent('saga_start')
    const storedSector = storedFilters.settore || null
    const storedMacrofamiglia = storedFilters.macrofamiglia || null
    const storedFamiglia = storedFilters.famiglia || null
    const storedClassificationPath = storedFilters.classification_path || null


    yield checkAndToggle(storedSector, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedMacrofamiglia, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedFamiglia, sectorsSelectors.getSelectedItem)
    yield checkAndToggle(storedClassificationPath, sectorsSelectors.getSelectedItem)

    emitSagaEvent('saga_end')
}

function* handleEtimFilters(storedFilters) {
    emitSagaEvent('saga_start')

    const storedEtimClass = storedFilters.etim_class || null

    if(storedEtimClass) yield put(sectorActions.setSelectedItemTree(storedEtimClass))

    emitSagaEvent('saga_end')
}

function* handleEtimAttributesFilters(storedFilters) {
    emitSagaEvent('saga_start');
    //console.warn('chiamato handleEtimAttributesFilters')
    const storedEtimAttributes = storedFilters.etim_features || {};
    //console.log(storedEtimAttributes)
    if (Object.keys(storedEtimAttributes).length === 0) {
        emitSagaEvent('saga_end');
        return;
    }

    yield take('FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS');

    // const { success } = yield race({
    //     success: take('FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS'),
    //     timeout: delay(3000)  // Timeout di 3 secondi (puoi regolarlo come necessario)
    // });
    //
    // if (!success) {
    //     console.warn("L'evento FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS non è arrivato, procedo comunque.");
    // }

    // Iterate over the etim_featuers and apply the right strategy
    for (const [id, feature] of Object.entries(storedEtimAttributes)) {
        yield put(fetchProductClassAttributeData(id));

        if(feature.type === 'range') yield take('FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS')

        // It there is a strategy for the type ( alphanumeric|logic|numeric ) it will be applied
        // otherwhise will dispatch the default toggleProductClassAttributeFilter
        const strategy = etimFeatureStrategies[feature.type];
        if (strategy) {
            yield* strategy(id, feature);
        } else {
            console.warn(`ETIM_FEATURES TYPE UNKNOWN: ${feature.type}. Dispatch default action.`);
            for (const val of feature.value) {
                yield put(toggleProductClassAttributeFilter(id, val));
            }
        }
    }

    emitSagaEvent('saga_end');
}

function* handleFuoriCartaceoFilters(storedFilters) {
    emitSagaEvent('saga_start');

    // Check for 'fuori_listino_cartaceo' in stored filters
    const storedFuoriListinoCartaceo = storedFilters.hasOwnProperty('fuori_listino_cartaceo')
        ? storedFilters.fuori_listino_cartaceo
        : null;

    // If no valid stored value, end the saga early
    if (storedFuoriListinoCartaceo === null) {
        emitSagaEvent('saga_end');
        return;
    }

    // Retrieve the current filter options from the Redux state
    const fuoriCartaceoFilter = yield select(state => filterSelectors.getFilter(state, constants.FUORI_CARTACEO_FILTER));

    // Find the matching option based on stored value, converting to string as needed
    const storedOption = fuoriCartaceoFilter?.options.find(
        (option) => option.value === storedFuoriListinoCartaceo.toString()
    );

    // If a matching option is found, dispatch action to set the filter
    if (storedOption) {
        yield put(actions.setFilterValue(constants.FUORI_CARTACEO_FILTER, storedOption));
    } else {
        console.warn(`No matching option found for fuori_listino_cartaceo value: ${storedFuoriListinoCartaceo}`);
    }

    emitSagaEvent('saga_end');
}

function* handlePriceRangeFilters(storedFilters) {
    emitSagaEvent('saga_start')
    const storedPriceRangeFrom = storedFilters.hasOwnProperty('prezzo_listino_from')
    const storedPriceRangeTo = storedFilters.hasOwnProperty('prezzo_listino_to')

    if(storedPriceRangeFrom === undefined || storedPriceRangeTo === undefined) {
        emitSagaEvent('saga_end')
        return
    }
    const priceRangeFrom = storedFilters.prezzo_listino_from
    const priceRangeTo = storedFilters.prezzo_listino_to
    const priceRange = [
        {
            min: priceRangeFrom,
            max: priceRangeTo,
        }]
    yield put(actions.setPriceRange(priceRange))

    emitSagaEvent('saga_end')
}
// Implement a strategy pattern in Javascript: an object used to switch the action we will dispatch
const etimFeatureStrategies = {
    alphanumeric: function* (id, feature) {
        for (const val of feature.value) {
            yield put(toggleProductClassAttributeFilter(id, val));
        }
    },
    numeric: function* (id, feature) {
        yield put(setProductClassAttributeNumeric(id, feature.value))
    },
    range: function* (id, feature) {
        // console.warn('range etim features => ', feature)
        const formattedValues = generateRangeValues(feature.value);

        // Dispatch each values as "min::max"
        for (const val of formattedValues) {
            yield put(toggleProductClassAttributeFilter(id, val));
        }
        //yield put(toggleProductClassAttributeFilter(id, feature.value))
    },
    logic: function* (id, feature) {
        const valueFromLabel = feature.value === true ? 'yes' : 'no';
        // console.warn("logic etim_features found")
        yield put(setProductClassAttributeLogic(id, valueFromLabel));
    }
}

function generateRangeValues(valueArray) {
    return valueArray.map(([min, max]) => `${min}::${max}`)
}

// Helper function for avoid toggle effect
function* checkAndToggle(filter, selector) {
    const selectedItem = yield select(selector);
    if (filter && filter !== selectedItem) {
        yield put(toggleTreeItem(filter));
    }
}
// Saga function that remove the localStorage saved filters
function* resetLocalStorageFiltersSaga(){
    // Delete local storage filters
     localStorage.removeItem('userFilters');
}

export default [
    takeEvery(actionTypes.SET_FILTER_VALUE, setFilterValueSaga),
    takeEvery(actionTypes.CHANGE_BRAND_FILTER_VALUE, changeBrandFilterSaga),
    takeEvery(actionTypes.LOAD_FILTERS_FROM_LOCALSTORAGE, loadFiltersFromLocalStorage),
    takeEvery(actionTypes.REFRESH_FILTERS_VALUES, refreshFiltersValues),
    takeEvery(actionTypes.RESET_LOCAL_STORAGE_FILTERS, resetLocalStorageFiltersSaga),
]
