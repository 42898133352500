import { actionTypes } from '../actions/productClassesActions';
import { Record, List, OrderedSet, OrderedMap /*, Map, Set*/ } from 'immutable';
// import startsWith from 'lodash/startsWith';

import { SelectFilterRecord, OptionRecord } from './helpers';
import * as constants from '../constants';
import { removeFilterFromLocalStorage } from '../utils/LocalStorageFilterUtils';

export const ProductClassAttributeRecord = Record({
    type: 'alphanumeric',
    label: null,
    key: null,
    isFetching: false,
    lastRequestHash: null,
    data: null
});

export const ProductClassFilterRecord = Record({
    value: null,
    label: null,
    count: 0
});

export const ProductClassAttributesRecord = Record({
    productClassFilter: SelectFilterRecord({ enabled: true }),
    attributes: OrderedMap({}),
    activeFilters: OrderedMap({}),
    isFetching: false
});

export const DEFAULT_STATE = ProductClassAttributesRecord();

export function createProductClassAttributes(raw = []) {
    const attributes = {};

    raw.forEach(c => {
        // const { key, label, values, type } = klass;

        // console.warn(c);

        // const filters = List(values.map(filter => filter));

        attributes[c.id] = ProductClassAttributeRecord({
            type: c.type,
            label: c.description,
            key: c.id
            // filters
        });
    });

    return OrderedMap(attributes);
}

function fetchStart(state, action) {
    return state.set('isFetching', true);
}

function fetchSuccess(state, action) {
    const { payload } = action;

    return state.set('attributes', createProductClassAttributes(payload)).set('isFetching', false);

    // let items = OrderedMap({});

    // data.forEach(item => {
    //     items = items.set(item.id, ItemRecord(item));
    // });

    // return state.mergeDeep({
    //     isFetching: false,
    //     total: meta.total,
    //     pages: meta.pages,
    //     itemsById: items,
    //     itemsId: Set(data.map(item => item.id))
    // });
}

function fetchFail(state, action) {
    return state.set('isFetching', false);
}

// function fetchAttributeData(state, action) {
//     return state;
// }

function fetchAttributeDataStart(state, action) {
    const { payload } = action;

    return state.setIn(['attributes', payload.id, 'isFetching'], true);
}

function formatAttributeData(data, type) {
    switch (type) {
        case constants.ATTRIBUTE_TYPE_ALPHANUMERIC:
            return data.map(o => ({
                value: o.id,
                label: o.description
            }));
        case constants.ATTRIBUTE_TYPE_NUMERIC:
        case constants.ATTRIBUTE_TYPE_LOGIC:
            return data[0];
        case constants.ATTRIBUTE_TYPE_RANGE:
            return data.map(o => {
                return {
                    value: `${o.from}::${o.to}`,
                    label: `${o.from} - ${o.to}`
                };
            });
        default:
            return data;
    }
}

function fetchAttributeDataSuccess(state, action) {
    const {
        payload: { response, id, requestHash }
    } = action;

    return state.updateIn(['attributes', id], attribute => {
        return attribute.merge({
            isFetching: false,
            lastRequestHash: requestHash,
            // TODO: ha senso tenere questi dati non immutabili???
            data: formatAttributeData(response.data, attribute.type)
        });
    });
}

function fetchAttributeDataFail(state, action) {
    const { payload } = action;

    // TODO: gestire errore
    return state.setIn(['attributes', payload.id, 'isFetching'], false);
}

function resetProductClassAttributeFilters(state, action) {
    const { payload: key } = action;

    // If a key is provided, reset that specific filter
    if (key) {
        return state.update('activeFilters', filters => filters.delete(key));
    }

    // If no key is provided, reset all filters
    return state.update('activeFilters', filters => {
        filters.keySeq().forEach((filterKey) => {
            filters = filters.delete(filterKey);
        });
        return filters;
    });
}

function resetAllProductClassAttributesFilters(state) {
    return state.set('activeFilters', OrderedMap({}));
}

function toggleProductClassAttributeFilter(state, action) {
    const {
        payload: { attribute, key }
    } = action;

    return state.update('activeFilters', filters => {
        // Se non esiste la chiave dell'attributo la creo e come valore
        // assegno un OrderedSet con l'id del valore selezionato
        if (!filters.get(attribute)) {
            return filters.set(attribute, OrderedSet([key]));
        }

        const actualValues = filters.get(attribute);

        // Se il valore selezionato è già presente...
        if (actualValues.has(key)) {
            // Se era l'unico valore rimasto cancello la chiave dell'attributo
            if (actualValues.count() === 1) {
                return filters.delete(attribute);
            }

            // Altrimenti cancello la chiave
            return filters.set(attribute, actualValues.delete(key));
        }

        // Se invece non è presente lo aggiunto all'OrderedSet dei valori selezionati
        return filters.set(attribute, actualValues.add(key));
    });
}

function setProductClassAttributeLogic(state, action) {
    const {
        payload: { attribute, value }
    } = action;

    return state.update('activeFilters', filters => {
        if (value === 'all') {
            return filters.delete(attribute);
        }

        return filters.set(attribute, value);
    });
}

function setProductClassAttributeNumeric(state, action) {
    const {
        payload: { attribute, range }
    } = action;

    return state.update('activeFilters', filters => {
        // if (value === 'all') {
        //     return filters.delete(attribute);
        // }

        return filters.set(attribute, range);
    });
}

function resetProductClass(state) {
    return state.merge({
        attributes: OrderedMap({}),
        activeFilters: OrderedMap({})
    });
}

function setProductClassValue(state, action) {
    return state.setIn(
        ['productClassFilter', 'value'],
        action.payload ? OptionRecord(action.payload) : null
    );
}

function setProductClassOptions(state, action) {
    return state.setIn(
        ['productClassFilter', 'options'],
        List(
            action.payload.map(o => {
                return OptionRecord({
                    value: o.id,
                    label: o.description,
                    code: o.name
                });
            })
        )
    );
}

const handlers = {
    [actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS]: resetProductClassAttributeFilters,
    [actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL]: resetAllProductClassAttributesFilters,
    [actionTypes.TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER]: toggleProductClassAttributeFilter,
    [actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC]: setProductClassAttributeLogic,
    [actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC]: setProductClassAttributeNumeric,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTES_START]: fetchStart,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTES_SUCCESS]: fetchSuccess,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTES_FAIL]: fetchFail,
    // [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA]: fetchAttributeData,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_START]: fetchAttributeDataStart,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_SUCCESS]: fetchAttributeDataSuccess,
    [actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA_FAIL]: fetchAttributeDataFail,
    [actionTypes.RESET_PRODUCT_CLASS]: resetProductClass,
    [actionTypes.SET_PRODUCT_CLASS_VALUE]: setProductClassValue,
    [actionTypes.SET_PRODUCT_CLASS_OPTIONS]: setProductClassOptions
};

export default function productClassReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

export const selectors = {
    getProductClassAttributes(state) {
        return state.productClasses.attributes;
    },
    getProductClassAttributesList(state) {
        return state.productClasses.attributes.toList();
    },
    getProductClassAttribute(state, id) {
        return state.productClasses.attributes.get(id);
    },
    getProductClassAttributesActiveFilters(state) {
        // let activeFilters = new Map({});

        // state.productClasses.activeFilters.forEach((values, attribute) => {
        // const [attribute, key] = filter.split('::');

        // if (activeFilters.has(attribute)) {
        //     activeFilters = activeFilters.update(attribute, filters => {
        //         return filters.add(key);
        //     });
        // } else {
        //     activeFilters = activeFilters.set(attribute, Set([key]));
        // }
        // });

        return state.productClasses.activeFilters;
    },
    getActiveFilters(state) {
        return state.productClasses.activeFilters;
    },
    getIsFetching(state) {
        return state.productClasses.isFetching;
    },
    getFilter(state) {
        return state.productClasses.productClassFilter;
    }
};
