import { call, put, select, fork, takeEvery } from 'redux-saga/effects';
import { hash, fromJS, is } from 'immutable';

import {
    actionTypes,
    actions,
    resetClassification,
    setClassificationValue,
    resetClassificationFilters
} from '../actions/sectorsActions';
import { selectors /*treeUtils, NodeRecord*/ } from '../reducers/sectorsReducer';
import {
    actions as productClassesActions,
    resetProductClass,
    resetProductClassAttribute, resetProductClassAttributeAll
} from '../actions/productClassesActions';
import { actions as filtersActions, resetAllFilters, setFilterValue } from '../actions/filtersActions';
import { selectors as productClassesSelectors } from '../reducers/productClassesReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
// import { setTree } from '../actions/sectorsActions';
import { fetchClassificationTree } from '../api';
import { fetchItems, fetchItemsFromLocalStorage } from '../actions/itemsActions';
import * as constants from '../constants';
import {
    getFilterFromLocalStorage,
    removeBrandRelatedFilterFromLocalStorage, removeFilterFromLocalStorage, removeFiltersFromLocalStorage,
    updateLocalStorage
} from '../utils/LocalStorageFilterUtils';
import * as api from '../api';
import { emitSagaEvent } from './sharedChannel';
import { getIsLoadingFromLocalStorage } from '../reducers/pageStateReducer';
import { delay } from 'redux-saga';
import { setIsChangingClassification } from '../actions/pageStateActions';

const classificationDependentFilters = [
    // constants.CATALOG_FILTER,
    constants.LINE_FILTER,
    constants.MODEL_FILTER,
    constants.SERIES_FILTER,
];

/******************************************************
 *  Function: fetchSectorsSaga
 *  Purpose: Handles the retrieval and reset of sector classifications within the product_tree structure
 *           (including etim_class, famiglia, macrofamiglia, settore).
 *
 *  Summary:
 *  - Determines if a classification change requires reset actions on various filters.
 *  - Manages localStorage and Redux state updates based on classification changes.
 *  - Ensures required data is reloaded, including brand and tree data when default classification is used.
 *
 *  Actions Called:
 *  - setIsChangingClassification: Flags the classification change process.
 *  - setSelectedItemTree: Clears selected item state in the product tree.
 *  - setProductClassValue: Resets product class selection.
 *  - resetFilters: Clears filters dependent on classification.
 *  - setFilterValue('brand'|'status'|'listini'|'fuoriCartaceo'): Resets filter values individually.
 *  - fetchItems: Initiates fetch for new items matching the updated classification.
 *  - api.fetchBrandFilter: Fetches brand filter options from API.
 *  - setFilterOptions(constants.BRAND_FILTER, ...): Updates brand filter options in the state.
 *  - fetchTree: Initiates tree fetching based on classification requirements.
 ******************************************************/

function* fetchSectorsSaga(action){
    const loadingFromLocalStorage = yield select(getIsLoadingFromLocalStorage)
    const previousClassification = action.meta.previousClassification?.toJS().value
    const newClassification = action.payload ? action.payload.value : null
    // const previousSelectedBrandsFilter = yield select((state) => filtersSelectors.getFilter(state, constants.BRAND_FILTER))
    // const previousSelectedBrands = (previousSelectedBrandsFilter?.value) ? previousSelectedBrandsFilter.value : null

    // We aren't loading from localStorage, so we are changing classification, we need to remove all previously data if classification is changed
    if(!loadingFromLocalStorage && previousClassification !== newClassification) {
        // First of all, we put the action of isChangingClassification
        yield put(setIsChangingClassification(true))

        // Remove filters from localStorage first
        const filters = ['famiglia', 'macrofamiglia', 'settore', 'classification_path', 'etim_class', 'etim_features']
        removeFiltersFromLocalStorage(filters)

        // Reset state
        yield put(actions.setSelectedItemTree(null))
        yield put(productClassesActions.setProductClassValue(null))
        yield put(filtersActions.resetFilters(classificationDependentFilters))
        yield delay(500) // adding a delay

        // Keep brand if we are in idrolab|etim classification
        if(newClassification !== 'idrolab' && newClassification !== 'etim') {
            yield put(setFilterValue('brand', null))
            // Remove stored filter from localStorage
            removeBrandRelatedFilterFromLocalStorage()
            removeFilterFromLocalStorage('brand')
        }

        yield put(setFilterValue('status', null))
        removeFilterFromLocalStorage('stato')
        yield put(setFilterValue('listini', null))
        removeFilterFromLocalStorage('listini')
        yield put(setFilterValue('fuoriCartaceo', null))
        removeFilterFromLocalStorage('fuori_listino_cartaceo')

        // fetch new items for the given new payload
        yield put(fetchItems())

        // tell the state we have complete changing classification
        yield put(setIsChangingClassification(false))

        // in case of default classification, we need to call an other method, fetchTree
        if(newClassification === constants.DEFAULT_CLASSIFICATION) {
            // Refetch brands to be sure we have all the brands in options
            const brands = yield call(api.fetchBrandFilter)
            yield put(filtersActions.setFilterOptions(constants.BRAND_FILTER, brands.data))
            yield fork(fetchTree, true)
        }

    }
}

export function* fetchTree(showLoading = false) {
    emitSagaEvent('saga_start')
    const apiPayload = yield select(filtersSelectors.getApiPayload);
    // console.log('fetchTree from sectorsSaga => ', apiPayload)
    const apiHash = hash(fromJS(apiPayload));

    const isFetching = yield select(selectors.getIsFetching);
    const currentApiHash = yield select(selectors.getCurrentApiHash);

    if (isFetching === true && apiHash === currentApiHash) {
        emitSagaEvent('saga_end')
        return true;
    }

    try {
        if (showLoading) {
            yield put(actions.fetchSectorsStart());
        }
        yield put(actions.setApiHash(apiHash));

        const res = yield call(fetchClassificationTree, apiPayload);

        yield put(actions.fetchSectorsSuccess(res));
        yield put(actions.setApiHash(null));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
    emitSagaEvent('saga_end')
}

function* toggleTreeItemSaga(action) {
    // TODO: tutta questa sezione è un quasi duplicato del metodo getSelectedTreeItemRecord del file sectorsReducers
    // const stateTree = yield select(selectors.getTree);
    const classificationType = yield select(selectors.getFilterValue);

    //console.log('classificationType from toggleTreeItemSaga => ', classificationType)

    const { id, depth } = action.payload;

    if (classificationType === constants.ETIM_CLASSIFICATION) {
        switch (depth) {
            // Se mi trovo a profondita 0 (= etim_group) allora verifico se esiste una classe etim eventualmente gia impostata nel filtro
            case 0:
                const item = yield select(selectors.getSelectedTreeItemRecord);
                const currentClass = yield select(productClassesSelectors.getFilter);

                // console.warn(item.toJS(), currentClass.toJS());

                // Se non c'è nessuna classe etim selezionata nel filtro proseguo
                if (currentClass.value === null) {
                    return true;
                }

                const checkParent = item.children.find((c) => c.id === currentClass.value.value);

                // Se l'elemento selezionato nell'albero è padre della classe attualmente selezionata nel filtro proseguo
                if (checkParent) {
                    return true;
                }

                // Altrimenti imposto la classe selezionata nel filtro a null
                yield put(productClassesActions.setProductClassValue(null));

                return true;
            // Se sono a profondita 2 l'oggetto selezionato è una classe ETIM quindi invio la relativa azione
            case 1:
                yield put(actions.toggleClass(id));
                break;
            default:
                return true;
        }
    } else {
        //console.log('action from toggleTreeItemSaga => ', action)
        yield put(fetchItems());
        /*let type = '';

        switch (depth) {
            case 0:
                type = 'settore';
                break;
            case 1:
                type = 'macrofamiglia';
                break;
            case 2:
                type = 'famiglia';
                break;
            default:
                break;
        }

        yield put(actions.toggleIdrolabClassification(id, type));*/
    }
    // }
}

function* toggleClassSaga(action) {
    const selectedItem = yield select(selectors.getSelectedItem);
    const productClassFilter = yield select(productClassesSelectors.getFilter);

    if (selectedItem !== null) {
        const productClass = productClassFilter.options.find((o) => o.value === action.payload);

        if (productClass) {
            yield put(productClassesActions.setProductClassValue(productClass.toJS()));
        }
    } else {
        yield put(productClassesActions.setProductClassValue(null));
    }
}

/*function* toggleIdrolabClassificationSaga(action) {
    yield put(fetchItems());
}*/


// ********************************************
// **   Helper Functions                     **
// **   These function assists to fetch data **
// **   for sectors                          **
// ********************************************

/******************************************************
 *  Function: classificationHasBrands
 *  Purpose: check if the given brands exists in the given Classification
 *
 ******************************************************/

export default [
    // takeEvery(actionTypes.FETCH_SECTORS, fetchSectorsSaga),
    takeEvery(actionTypes.TOGGLE_TREE_ITEM, toggleTreeItemSaga),
    takeEvery(actionTypes.TOGGLE_CLASS, toggleClassSaga),
    // takeEvery(actionTypes.TOGGLE_IDROLAB_CLASSIFICATION, toggleIdrolabClassificationSaga),
    takeEvery(actionTypes.SET_CLASSIFICATION_VALUE, fetchSectorsSaga),
];
